import axios from 'axios';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import config from '../../utils/config';
import moment from 'moment';
import StartGameModal from '../helpers/StartGameModal';
import Loader from '../helpers/Loader';



const ResumeGame = config.getResumeGame;
const getAnswerUrl = config.getAllAnswersForAllGames;
const getAllAnswersForAllGames = config.getAllAnswersForAllGamesNew;
const GameId = '1';

const ResumeGameBoard = () => {
  const history = useHistory();
  const [resumeData, setResumeData] = useState();
  const [isResumeLoading, setResumeLoading] = useState(false);
  const [isPopUpActive, setPopUpActive] = useState(false);
  const [totalAnsweredExercise, setTotalAnswered] = useState();
  let token = localStorage.getItem('token');
  useEffect(() => { getResumeGame() }, [])

  const getResumeGame = () => {
    setResumeLoading(true)
    const url = getAnswerUrl;
    var config = {
      method: 'get',
      url: url,
      headers: { 'Authorization': 'Bearer ' + token },
    };
    axios(config)
      .then(function (response) {
        setResumeData(response.data[response.data.length - 1]);
        setResumeLoading(false)
        setTotalAnswered(response.data[response.data.length - 1].totalAnswered)
      })
      .catch(function (error) {
        setResumeLoading(false)
        //fail silently
      });
  }
  const handleResume = () => {
    history.push({
      pathname: '/gameInterface',
      state: {
        GameId,
        lastExercise: resumeData.lastAnsweredExercise,
        resume: true,
        newSession: false
      }
    })
  }
  const handleNewGame = () => {
    history.push({
      pathname: '/gameInterface',
      state: {
        GameId,
        resume: false,
        newSession: true
      }
    })
  }

  return (
    <>
      {isResumeLoading ? <Loader /> :
        <div className="level-item title has-text-centered is-2">
          {
            resumeData ?
              <p>Welcome back, to Game of Falls</p>
              :
              <p>Welcome, to Game of Falls</p>
          }
        </div>
      }
      {
        resumeData ?
          <div>
            <p className=" title has-text-centered is-4">You have answered {resumeData && totalAnsweredExercise} out of {resumeData && resumeData.totalExercises} exercises</p>
            <div className="subtitle has-text-centered is-size-6 is-size-6">Last played on {moment(resumeData.answerDate).format('llll')}</div>
            <p className="subtitle has-text-centered is-size-6 mb-6">Last exercise answered: #{resumeData && resumeData.lastAnsweredExercise}</p>
            {/* <p className="subtitle has-text-centered is-size-6">Total Exercises : {resumeData && resumeData.totalExercises}</p> */}
          </div>

          : null
      }
      <StartGameModal
        isActive={isPopUpActive}
        handleCancel={() => {
          setPopUpActive(false)
        }}
        handleStart={handleNewGame}
      />
      {isResumeLoading ? null :
        <div className="subtitle buttons is-centered mt-4">
          <button className="button is-info px-6" onClick={() => {
            setPopUpActive(true)
          }}>Start New</button>
          {
            resumeData ?
              <button disabled={resumeData && resumeData.finished} className={`button is-success px-6 ${isResumeLoading ? 'is-loading' : ''}   `} onClick={handleResume}>Resume Play
                <span title="Badge top right" className=" badge is-info"> {resumeData ? `${resumeData && totalAnsweredExercise}/${resumeData && resumeData.totalExercises}` : ''}</span>
              </button>
              : null}
        </div>
      }
    </>
  )
}
export default ResumeGameBoard;