/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import QuestionOptions from "../helpers/QuestionOptions";
const SurveyForm = ({ questions, answers, setAnswers, setData }) => {
  let user = JSON.parse(localStorage.getItem("user"));

  const handleQuestionAnswer = (id, option) => {
    const filteredArray = answers.filter((item) => item.questionId !== id);

    const combinedArray = [
      ...filteredArray,
      {
        questionId: id,
        answer: option,
      },
    ];
    setAnswers(combinedArray);

    const data = {
      userId: user.userId,
      responses: combinedArray,
    };

    setData(data);
  };

  return (
    <div>
      {questions?.map((option, index) => (
        <div key={index}>
          <h3 className="has-text-primary">
            {index + 1}
            {". "}
            {questions[index]?.question}
          </h3>

          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              {questions[index]?.options?.map((item) => {
                return (
                  <>
                    <QuestionOptions
                      item={item}
                      questionId={questions[index].questionId}
                      handleQuestionAnswer={handleQuestionAnswer}
                    />
                  </>
                );
              })}
            </RadioGroup>
          </FormControl>
        </div>
      ))}
    </div>
  );
};

export default SurveyForm;
