import axios from 'axios';
import { useState } from 'react';
import { useForm } from "react-hook-form";
import config from '../../utils/config';
import Loader from '../helpers/Loader';

const ForgotPasswordUrl = config.commonForgotPassword

const ForgotPassword = ({myEmail}) => {
  const [isLoading , setLoading ] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isErrorMessage, setErrorMessage ] = useState();
  const [isSuccessMessage, setSuccessMessage] = useState();
  const { register, handleSubmit, watch, errors } = useForm();

  const sendForgotPasswordMail = (data) => {
    setLoading(true);
    var config = {
      method: 'post',
      url: ForgotPasswordUrl,
      headers: { },
      data : data
    };
    axios(config)
    .then(function (response) {
      setSuccessMessage(response.data.message)
      setLoading(false)
    })
    .catch(function (error) {
      let err = '';
      if(error.response.data.errors.email){
        err = error.response.data.errors.email
      }else{
        err = error.response.data.errors
      }
      
      setErrorMessage(err)
      setLoading(false)
    });
  }

  const toggleModal = () => {
    setErrorMessage()
    setSuccessMessage()
    setModalOpen(!isModalOpen);
  }

  const closeErrorNotification = () => {
    setErrorMessage();
  }

  const ModalContent = () => {
    if(isSuccessMessage){
      return (
        <>
          {isLoading ?  <Loader size="small"  />  : <div className="notification is-success is-light">{isSuccessMessage}</div> }
        </>
      )
    }
    if(isErrorMessage){
      return(
        <>
          {
            isLoading ? <Loader size="small"  />  :    <div className="notification is-danger  is-light"><button className="delete" onClick={closeErrorNotification}>Try Again</button>{isErrorMessage}</div>
          }
        </>
      )
    }else{
      return(
        <>
         {
           isLoading ? <Loader size="small"  />  : 
           <div className="field">
            <p className="control has-icons-left ">
              <input className={`input ${errors.email && 'is-danger'}`} type="email" placeholder="Email" name="email" defaultValue={myEmail} ref={register({required : 'Email Required'})}/>
              <span className="icon is-small is-left">
                <i className="fas fa-envelope"></i>
              </span>
              {errors.email && <p className="help is-danger">{errors.email.message}</p>}
            </p>
          </div>
         }
        </>
      )
    }
    
  }

  const ModalFooterButton = () => {
    if(isErrorMessage){
      return (
        <button className={`button is-danger ${isLoading ? 'is-loading' : ''}` }  onClick={closeErrorNotification} >Failed ! Try Again</button>
      )
    }
    if(isSuccessMessage){
      return 
    }else{
      return (
        <button className={`button is-success ${isLoading ? 'is-loading' : ' '} `} type="submit" >Send Email</button>
      )
    }
  }

  const ModalView = () => {
    return (
      <form  onSubmit={handleSubmit(sendForgotPasswordMail)}>
        <div className={`modal ${isModalOpen ? 'is-active' : ''} `}>
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Reset Password</p>
              <button className="delete" aria-label="close" onClick={toggleModal} ></button>
            </header>
            <section className="modal-card-body">
              {ModalContent() }
            </section>
            <footer className="modal-card-foot ">
              {ModalFooterButton()}
            </footer>
          </div>
        </div>
      </form>
    )
  }
  return(
    <>
      {
        !isModalOpen ?  <a className="helper has-text-info" onClick={toggleModal}>Forgot Password</a> : <ModalView  />
      }
    </>
  )
}
export default ForgotPassword;
