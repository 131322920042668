import React from "react";
import {
  Redirect, Route
} from "react-router-dom";

const PublicRoute = ({component: Component, restricted, ...rest}) => {
  
  const token = localStorage.getItem('token');
  
  return  (
    <Route 
      {...rest}
      render={props => (
        token  && restricted ? <Redirect to="/dashboard" /> : <Component {...props} /> 
      )}
    />
  )
}

export default PublicRoute;