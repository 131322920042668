import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import config from '../../utils/config';
import HeaderFooterWrapper from '../helpers/HeaderFooterWrapper';
import Panel from '../helpers/Panel';

const signUpUrl = config.facility_sign_up;

const FacilityUserLogin = () => {
    const BaseUrl = window.location.href.split('/')[2];
  
    const [ isLoading, setLoading ] = useState(false);
    const { register, handleSubmit, watch, errors } = useForm({mode : 'onChange'});
    const [isErrorMessage, setErrorMessage] = useState();

    const onSubmit =  data => { 
    setLoading(true);

    var config = {
      method: 'post',
      url: signUpUrl,
      headers: { },
      data : data
    };
    axios(config)
    .then(function (response) {
      setLoading(false)
      window.location.replace('http://'+BaseUrl+'/facilityUserLogin');
    })
    .catch(function (error) {
      var err = error.response.data.message
      setErrorMessage(err)
      setLoading(false)
    });
    };

    const renderContent = () => {
      if(isErrorMessage){
        return(
          <div    className="message is-danger">
            <div    className="message-body">{isErrorMessage}</div>
          </div>
        )
      }
    }
    return (
      <HeaderFooterWrapper>
        <div className="columns  py-3">
          <div className="column is-three-fifths is-offset-one-fifth  mt-2">
              <Panel 
                title="Sign Up"
                subTitle="Login"
                subTitleLink="/facilityUserLogin"
              >
              {renderContent()}
                <form  onSubmit={handleSubmit(onSubmit)}>
                <div className="field">
                  <p className="control has-icons-left">
                    <input className={`input ${errors.email && 'is-danger'}`} type="email" placeholder="Email" name="email" ref={register({required: "Email Required"})}/>
                    <span className="icon is-small is-left">
                    <i className="fas fa-envelope"></i>
                    </span>
                    {errors.email && <p    className="help is-danger">{errors.email.message}</p>}
                  </p>
                </div>
                <div className="field">
                  <p className="control has-icons-left">
                    <input className={`input ${errors.password && 'is-danger'}`} type="password" placeholder="Password" name="password" ref={register({required : "Pssword Required", minLength : {value : 8, message : 'Password too short minimum 8 digit'}})}/>
                    <span className="icon is-small is-left">
                     <i className="fas fa-lock"></i>
                    </span>
                    {errors.password && <p    className="help is-danger">{errors.password.message}</p>}
                  </p>
                </div>
                <div className="field">
                  <p className="control has-icons-left">
                    <input className="input" type="text" placeholder="Facility Code" name="facilityCode" ref={register}/>
                    <span className="icon is-small is-left">
                      <i className="fas fa-hospital-alt"></i>
                    </span>
                  </p>
                </div>
                <div className="field">
                  <p className="control">
                    <button className={`button is-success ${isLoading ? 'is-loading' : ''} is-pulled-right`} type="submit" >Login</button>
                  </p>
                </div>
            </form>
          </Panel>
        </div>
      </div>
    </HeaderFooterWrapper>
  )
}

export default  FacilityUserLogin ;