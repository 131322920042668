import axios from 'axios';
import { useEffect, useState } from 'react';
import moment from 'moment';
import config from '../../utils/config';
import Loader from '../helpers/Loader';
import ReportParts from '../helpers/ReportParts';
import { useHistory } from 'react-router-dom';

const getAnswerUrl = config.getAllAnswersForAllGames;
const getAnswerUrlLastAnswered = config.getLastAnswersForAllGames;
const ResumeGame = config.getResumeGame;
const GameId = '1';


const Report = () => {

  const [isLoading, setLoading] = useState(false)
  const [exercises, setExercises] = useState([]);
  const [data, setData] = useState({})
  const [dataLength, setDataLength] = useState(0)
  const [totalExerciseAnswered, setTotalExerciseAnswered] = useState()
  const [totalExercise, setTotalExercise] = useState()
  const [totalskip, setTotalSkip] = useState()
  const [totalRepats, setTotalRepeats] = useState()
  const [lastPlayed, setLastPlayed] = useState()
  const [attemptPerPart, setAttemptPerPart] = useState()
  const [multipleTryPercentage, setMultipleTryPercentage] = useState()
  const [firstTryPerecntage, setFirstTryPerecntage] = useState()
  const [firstTryPerecnfirstPercentagetage, setfirstTryPerecnfirstPercentagetage] = useState([])
  const [correctPerecntage, setCorrectPercentage] = useState()
  const [correctPlayedForProgress, SetcorrectPlayedForProgress] = useState()
  const [totalExercisePerPart, setTotalExercisePerPart] = useState()
  const [startExerciseDate, setExerciseDate] = useState()
  const [percentage, setPercentage] = useState()
  const [multiplePercentage, setMultiplePercentage] = useState()
  const [firstTime, setFirstTime] = useState(false)
  const [counAttempt, setAttempt] = useState()
  const [checkSkipped, setSkipped] = useState(false)
  const [checkIncomplete, setIncomplete] = useState(false)
  const [correctOnFirstAttempt, setCorrectOnFirstAttempt] = useState(false)


  const history = useHistory();

  let token = localStorage.getItem('token');
  useEffect(() => {
    fetchAnswer()
  },
    [])

  const fetchAnswer = () => {
    // console.log("fetcehing ansgwewes")
    setLoading(true)
    var config = {
      method: 'get',
      url: getAnswerUrl,
      headers: { 'Authorization': 'Bearer ' + token },
    };
    axios(config).then(function (response) {
      setData(response.data[response.data.length-1]);
      setDataLength(response.data.length)
      setExercises(response.data[response.data.length-1].exercises)
      scoreCalculation(response.data[response.data.length-1])
      console.log(data, "results dataa")

      setLoading(false)
    }).catch(function (error) {
      setLoading(false)
    });
  }

  const scoreCalculation = (data) => {
    setLoading(true)
    // console.log(data, "results dataa")
    let totalSkipped = 0;
    let totalRepeats = 0;
    let countFirstTry = 0;
    let totalAttemptsPerPart = 0;
    let totalIncorrect = 0;
    let totalCorrect = 0;
    let correctOnFirstTry = 0;
    setLastPlayed(data && data.answerDate)
    setTotalExerciseAnswered(data.totalAnswered)
    setTotalExercise(data.totalExercises)
    for (let item of data.exercises) {
      // console.log(item.skip)
      if (item.skip === true) {
        totalSkipped = totalSkipped + 1
      }
      totalRepeats = totalRepeats + item.repeats
    }

    for (let item of data.exercises) {
      // console.log(item)
      let main = item.main
      // console.log(main)
      for (let chunk of main) {
        
        if (chunk.attempt_outcome === "incorrect") {
          totalIncorrect = totalIncorrect + 1;
        }
        if (chunk.attempt_outcome === "correct") {
          totalCorrect = totalCorrect + 1;
        }
      }
      if (item.main[0].attempt_outcome === "correct") {
        correctOnFirstTry = correctOnFirstTry + 1;
      }
    }

    let correctOnFirstTryPercentage = correctOnFirstTry > 0 ? (correctOnFirstTry / data.totalAnswered) * 100 : 0
    let incorrectPercentage = totalIncorrect > 0 ? (totalIncorrect + totalCorrect) / totalExerciseAnswered * 100 : 0
    let multiplePercentageCalculated = totalCorrect / data.totalAnswered * 100
    
    setTotalExercisePerPart(totalExercise)
    setTotalRepeats(totalRepeats)
    setTotalSkip(totalSkipped)
    setCorrectOnFirstAttempt(correctOnFirstTry)
    setPercentage(correctOnFirstTryPercentage)
    setMultiplePercentage(multiplePercentageCalculated)
    setLoading(false)
  }

  const handleExerciseClick = (key) => {
    history.push({
      pathname: '/gameInterface',
      state: {
        GameId,
        lastExercise: key,
        resume: true
      }
    })
  }

  return (
    <>
      {
        isLoading ? <Loader /> :
          <>
            <div className="box-report box card-has-shadow is-size-6 mx-1">
              {
                dataLength >= 1 ?
                  <div className="">
                    <span className="progress-has-value">Answered {`${totalExerciseAnswered ? totalExerciseAnswered : '0'}/${totalExercise ? totalExercise : '0'}`} exercises</span>
                    <progress
                      className="progress is-success is-medium show-value"
                      value={totalExerciseAnswered}
                      max={totalExercise}
                    >
                    </progress>
                  </div>
                  : <div className="level-item">Your score and results will be displayed here</div>
              }
            </div>

            <div className="container">

              <div className="my-6">
                <div class="box-has-outline-black">
                  <div className="columns is-mobile">
                  {
                    isLoading
                    ?
                    <Loader />
                    :
                    <div className="column">
                      {
                        dataLength >= 1 ?
                          <div>
                            <p className="subtitle is-6 level-item">
                              <span className="has-text-grey has-text-centered">You have answered {correctOnFirstAttempt} out of {totalExerciseAnswered} {totalExerciseAnswered === 1 ? 'exercise' : 'exercises'  } on the first try.</span>
                            </p>
                            <p className="subtitle is-6 level-item">
                              <span className="has-text-grey">First attempt correct score: {Math.round(percentage)}%</span>
                            </p>
                            <p className="subtitle is-6 level-item">
                              {isLoading ? <Loader /> : <span className="has-text-grey">Multiple try score: {Math.round(multiplePercentage)}%</span>}
                            </p>
                          </div>
                          : null
                      }
                    </div>

                  }
                  </div>
                </div>
                <ReportParts
                  partName={["Part 1: ", <p style={{ 'cursor': 'pointer' }} onClick={() => handleExerciseClick(0)} className="title is-6">RECOGNIZING HAZARDS</p>]}
                  data={exercises}
                  chp_start={1}
                  chp_end={3}
                  key={1}
                />
                <ReportParts
                  partName={["Part 2: ", <p style={{ 'cursor': 'pointer' }} onClick={() => handleExerciseClick(3)} className="title is-6">RECOGNIZING SIGNALS </p>]}
                  data={exercises}
                  chp_start={4}
                  chp_end={10}
                  key={2}
                />
                <ReportParts
                  partName={["Part 3: ", <p style={{ 'cursor': 'pointer' }} onClick={() => handleExerciseClick(10)} className="title is-6">OTHER TYPES OF SIGNALS</p>]}
                  data={exercises}
                  chp_start={11}
                  chp_end={22}
                  key={3}
                />
                <ReportParts
                  partName={["Part 4: ", <p style={{ 'cursor': 'pointer' }} onClick={() => handleExerciseClick(22)} className="title is-6">UNDERSTANDING UNSEEN SIGNALS</p>]}
                  data={exercises}
                  chp_start={23}
                  chp_end={26}
                  key={4}
                />
                <ReportParts
                  partName={["Part 5: ", <p style={{ 'cursor': 'pointer' }} onClick={() => handleExerciseClick(26)} className="title is-6">PRACTICING WORKING WITH SIGNALS AND REMINDERS </p>]}
                  data={exercises}
                  chp_start={27}
                  chp_end={31}
                  key={5}
                />
                <ReportParts
                  partName={["Part 6: ", <p style={{ 'cursor': 'pointer' }} onClick={() => handleExerciseClick(31)} className="title is-6">MORE HOME-BASED PRACTICE</p>]}
                  data={exercises}
                  chp_start={32}
                  chp_end={35}
                  key={6}
                />
                <ReportParts
                  partName={["Part 7: ", <p style={{ 'cursor': 'pointer' }} onClick={() => handleExerciseClick(35)} className="title is-6">PERSONAL ITEMS</p>]}
                  data={exercises}
                  chp_start={36}
                  chp_end={41}
                  key={7}
                />
                <ReportParts
                  partName={["Part 8: ", <p style={{ 'cursor': 'pointer' }} onClick={() => handleExerciseClick(41)} className="title is-6">SUMMARY AND REVIEW</p>]}
                  data={exercises}
                  chp_start={42}
                  chp_end={50}
                  key={8}
                />

              </div>
              <div className="level-item is-size-7">Last played on {moment(lastPlayed).format('ll')}</div>
            </div>
          </>
      }
    </>
  )
}
export default Report