import ReactPlayer from 'react-player';
import videoUrl from '../../assets/intro.mp4';

//const videoUrl = config.instructional_video;
const IncorrectModal_02 = ({ isActive, toggleActive, onVideoEnded }) => {

  return (
    <div className={`modal modal-fx-fadeInScale is-clipped ${isActive ? 'is-active' : ''}`}>
    <button className="modal-close is-large" aria-label="close" onClick={toggleActive}></button>
      <div className="modal-background" onClick={toggleActive}></div>
      <div className="modal-content" onClick={toggleActive}>
        <ReactPlayer
          width="100%"
          height="100%"
          playing={isActive}
          url={videoUrl}
          onEnded={onVideoEnded}
          controls={true}
        />
      </div>
      <button className="modal-close is-large" aria-label="close" onClick={toggleActive}></button>
    </div>
  )
}

export default IncorrectModal_02;