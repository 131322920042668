import React from 'react';
import HeaderFooterWrapper from '../helpers/HeaderFooterWrapper'
import IndividualUserForgotPassword from './IndividualUserForgotPassword';

const IndividualUserLogin = ( ) => {
  
  return (
    <HeaderFooterWrapper>
      <h1>Individual user</h1>
      <IndividualUserForgotPassword />
    </HeaderFooterWrapper>
    
  )
}

export default IndividualUserLogin;