import React from 'react';

const FeedbackModal = ({ isActive, handleCancel, onFeedbackInput, handleFeedbackSubmit, feedbackValue }) => {
    return (
        <div className={`modal modal-fx-fadeInScale is-clipped ${isActive ? 'is-active' : ''}`}>
            <div className="modal-background"></div>
            <div className="modal-content">
                <div className="card">
                    <div className="card-content has-text-centered">
                        <h1 className="is-size-5 has-text-default"> Is there anything you would like to add about your experience with the "Game of Falls"?</h1>
                        <textarea placeholder="Enter your feedback here..." name="feedback" id="message" rows="5" className="textarea" onChange={onFeedbackInput} value={feedbackValue} required></textarea>
                    </div>
                    <footer className="card-footer ">
                        <p className=" card-footer-item is-danger has-background-primary has-text-dark is-clickable" onClick={handleFeedbackSubmit}>Submit Feedback</p>
                        <p className=" card-footer-item is-warning has-background-warning has-text-dark is-clickable" onClick={handleCancel}>Cancel</p>
                    </footer>
                </div>
            </div>
        </div>
    );
};

export default FeedbackModal;