import React from 'react';
import Footer from './Footer';

const HeaderFooterWrapperApp = ({children}) => {
  return (
    <div id="Xmain" className="main" style={{overflowY : 'hidden', overflowX : 'hidden', position : 'static', height : 'auto'}}>
        <main className="main-content" >
          {children}
        </main>
      <Footer  />
    </div>
  )
}

export default HeaderFooterWrapperApp;