import React from "react";
import RoutHandler from './components/routes/RoutHandler';
import './sass/app.scss';

function App() { 
  return (
    <RoutHandler />
  );
}

export default App;
