import HeaderFooterWrapperApp from "./helpers/HeaderFooterWrapperApp";

const PrivacyPolicyApp = () => {
  return (
    <>
      <HeaderFooterWrapperApp>
        <section class="hero has-2wayview-bg columns is-vcentered">
          <div class="hero-body column is-12 is-vcentered">
            <p class="subtitle has-text-weight-bold has-text-centered is-size-3">
              PRIVACY POLICY
            </p>
          </div>
        </section>
        <div className="container ">
          <div className="content p-5 has-text-left">
            <div className="block has-text-white">
              This privacy policy governs your use of the software application
              “Game of Falls” (the “App”) for mobile devices that was created by
              IJMCM LLC. The App is an educational program to help manage falls
              risk.
            </div>
            <div className="block has-text-white">
              This policy is effective as of the date that you access the App.
            </div>
            <div className="block has-text-white">
              BY USING THE APP, YOU GIVE YOUR CONSENT THAT ALL PERSONALLY
              IDENTIFIABLE INFORMATION THAT YOU SUBMIT OR THAT IS COLLECTED
              THROUGH THE APP, NAMELY YOUR E-MAIL ADDRESS AND PERSONAL CONTACT
              DETAILS, MAY BE PROCESSED BY IJMCM LLC IN THE MANNER AND FOR THE
              PURPOSES DESCRIBED IN THIS PRIVACY POLICY.
            </div>
            <div className="block has-text-white">
              If you do not agree with the terms of this Privacy Policy, you
              should cease downloading, installing or using the App immediately.
              Continued downloading constitutes your consent to the terms of
              this Privacy Policy.
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                What information does the App obtain and how is it used?
              </strong>
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                User Provided Information
              </strong>
            </div>
            <div className="block has-text-white">
              The App obtains the information you provide when you download and
              register the App. Registration with us is optional. However,
              please keep in mind that you may not be able to use some of the
              features offered by the App unless you register with us.
            </div>
            <div className="block has-text-white">
              When you register with us and use the App, you generally provide
              <br /> -- (a) your name, email address, age, user name, password
              and other registration information; <br /> -- (b)
              transaction-related information, such as when you make purchases,
              respond to any offers, or download or use applications from us;{" "}
              <br /> -- (c) information you provide us when you contact us for
              help; <br /> -- (d) credit card information for purchase and use
              of the App, and; <br /> -- (e) information you enter into our
              system when using the App, such as contact information and project
              management information.
            </div>
            <div className="block has-text-white">
              We may also use the information you provided us to contact your
              from time to time to provide you with important information,
              required notices and marketing promotions.
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                Automatically Collected Information
              </strong>
            </div>
            <div className="block has-text-white">
              In addition, the App may collect certain information
              automatically, including, but not limited to, the type of mobile
              device you use, your mobile devices unique device ID, the IP
              address of your mobile device, your mobile operating system, the
              type of mobile Internet browsers you use, and information about
              the way you use the App.
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                Does the App collect precise real time location information of
                the device?
              </strong>
            </div>
            <div className="block has-text-white">
              This App does not collect information about the location of your
              mobile device.
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                Legal Basis for Processing Personal Data Under General Data
                Protection Regulation (GDPR)
              </strong>
            </div>
            <div className="block has-text-white">
              If you are from the European Economic Area (EEA), IJMCM LLC’s
              legal basis for collecting and using the personal information
              described in this Privacy Policy depends on the Personal Data we
              collect and the specific context in which we collect it. Personal
              Data means data about a living individual who can be identified
              from those data (or from those and other information either in our
              possession or likely to come into our possession).
            </div>
            <div className="block has-text-white">
              IJMCM LLC, Inc may process your Personal Data because:
              <br /> -- We need to perform a contract with you
              <br /> -- You have given us permission to do so
              <br /> -- The processing is in our legitimate interests and it’s
              not overridden by your rights
              <br /> -- For payment processing purposes
              <br /> -- To comply with the law
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                Do third parties see and/or have access to information obtained
                by the App?
              </strong>
            </div>
            <div className="block has-text-white">
              Aggregated, anonymized data is periodically transmitted to
              external services to help us improve the App and our service. We
              will share your information with third parties only in the ways
              that are described in this Privacy Policy.
            </div>
            <div className="block has-text-white">
              We may disclose User Provided Data and Automatically Collected
              Information:
              <br /> -- To third parties, such as sponsors and underwriters who
              may have provided the App to you free of charge, who provide
              products, information, facilities, or services that App users may
              be interested in directly or indirectly purchasing or obtaining;
              <br /> -- As required by law, such as to comply with a subpoena,
              or similar legal process;
              <br /> -- When we believe in good faith that disclosure is
              necessary to protect our rights, protect your safety or the safety
              of others, investigate fraud, or respond to a government request;
              <br /> -- With our trusted services providers who work on our
              behalf, do not have an independent use of the information we
              disclose to them, and have agreed to adhere to the rules set forth
              in this Privacy Policy;
              <br /> -- If IJMCM LLC, Inc is involved in a merger, acquisition,
              or sale of all or a portion of its assets, you will be notified
              via email and/or a prominent notice on our Web site of any change
              in ownership or uses of this information, as well as any choices
              you may have regarding this information; and
              <br /> -- To others with your consent.
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                What are my opt-out rights?
              </strong>
            </div>
            <div className="block has-text-white">
              You can stop all collection of information by the App easily by
              uninstalling the App. You may use the standard uninstall processes
              as may be available as part of your mobile device or via the
              mobile application marketplace or network. You can also request to
              opt-out via email, at <text className="block has-text-black">admin@ijmcm.com</text>.
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                Data Retention Policy, Managing Your Information
              </strong>
            </div>
            <div className="block has-text-white">
              IJMCM LLC, Inc will retain your User Provided Data and Personal
              Data only for as long as is necessary for the purposes set out in
              this Privacy Policy. “Personal Data” means data about a living
              individual who can be identified from those data (or from those
              and other information either in our possession or likely to come
              into our possession). We will retain and use your User Provided
              Data and Personal Data to the extent necessary to comply with our
              legal obligations (for example, if we are required to retain your
              data to comply with applicable laws), resolve disputes, and
              enforce our legal agreements and policies. If you would like us to
              delete User Provided Data that you have provided via the App,
              please contact us at <text className="block has-text-black">admin@ijmcm.com</text> and we will respond in a
              reasonable time. Please note that some or all of the User Provided
              Data may be required in order for the App to function properly.
            </div>
            <div className="block has-text-white">
              IJMCM LLC, Inc will also retain Automatically Collected
              Information for internal analysis purposes. Automatically
              Collected Information is generally retained for a shorter period
              of time, except when this data is used to strengthen the security
              or to improve the functionality of our App and service, or we are
              legally obligated to retain this data for longer time periods.
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                Children
              </strong>
            </div>
            <div className="block has-text-white">
              We do not use the App to knowingly solicit data from or market to
              children under the age of 18. If a parent or guardian becomes
              aware that his or her child has provided us with information
              without their consent, he or she should contact us at <text className="block has-text-black">admin@ijmcm.com</text> .We will delete such information from our files
              within a reasonable time.
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                Security
              </strong>
            </div>
            <div className="block has-text-white">
              We are concerned about safeguarding the confidentiality of your
              information. We provide physical, electronic, and procedural
              safeguards to protect information we process and maintain. For
              example, we limit access to this information to authorized
              employees and contractors who need to know that information in
              order to operate, develop or improve our App. Please be aware
              that, although we endeavor provide reasonable security for
              information we process and maintain, no security system can
              prevent all potential security breaches.
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                Your Data Protection Rights Under General Data Protection
                Regulation (GDPR)
              </strong>
            </div>
            <div className="block has-text-white">
              If you reside in the European Economic Area, you benefit from
              certain rights under the General Data Protection Regulation (EU)
              2016/679. While some of these rights apply generally, certain
              rights apply only in certain limited cases. We describe these
              rights below in a summary format, but mandated applicable law
              shall govern.
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                ACCESS AND PORTING.
              </strong>
              <br />
              You can access much of your information by logging into your
              account. Where legally required, we can provide your information
              upon your request. Note that, in accordance with applicable law,
              information will not be provided where doing so would adversely
              affect the rights (including the intellectual property rights) of
              others.
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                CHANGE, RESTRICT, LIMIT.
              </strong>
              <br />
              If you believe that any information we are holding on you is
              incorrect or incomplete, please e-mail us as soon as possible, at <text className="block has-text-black">admin@ijmcm.com</text> We will promptly correct any information found to
              be incorrect.
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                DELETE.
              </strong>
              <br />
              You have the right to end this relationship is by deleting your
              account. If you choose to do so, we will delete all of your
              personal data from our systems, including backups within 30 days,
              unless there is a legitimate business interest to keep the data,
              such as to comply with legal obligations, to enforce agreements or
              to resolve disputes.
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                FORGOTTEN.
              </strong>
              <br />
              You have the right to be forgotten. If you choose to delete your
              profile, we will delete all associated information and it will be
              like you never signed up in the first place (with the exceptions
              mentioned above, meaning that we may, for a legitimate reason,
              have the right to keep some of your data).
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                OBJECT.
              </strong>
              <br />
              If we process your information based on our legitimate interests
              explained above, or in the public interest, you can object to this
              processing in certain circumstances. In such cases, we will cease
              processing your information unless we have compelling legitimate
              grounds to continue processing or where it is needed for legal
              reasons.
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                REVOKE CONSENT.
              </strong>
              <br />
              Where you have previously provided your consent, you have the
              right to withdraw your consent to the processing of your
              information at any time. In certain cases, we may continue to
              process your information after you have withdrawn consent if we
              have a legal basis to do so or if your withdrawal of consent was
              limited to certain processing activities.
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                COMPLAIN.
              </strong>
              <br />
              Should you wish to raise a concern about our use of your
              information (and without prejudice to any other rights you may
              have), you have the right to do so with your local data protection
              authority.
            </div>
            <div className="block has-text-white">
              If you wish to be informed what Personal Data we hold about you
              and if you want it to be removed from our systems, please contact <text className="block has-text-black">admin@ijmcm.com</text>.
            </div>
            <div className="block has-text-white">
              In certain circumstances, you have the following data protection
              rights:
              <br /> --
              <strong className="block has-text-white">
                {" "}
                The right to access, update or to delete the information we have
                on you.{" "}
              </strong>
              <br />
              Whenever made possible, you can access, update or request deletion
              of your Personal Data directly within your account settings
              section. If you are unable to perform these actions yourself,
              please contact us to assist you.
              <br /> --
              <strong className="block has-text-white">
                {" "}
                The right of rectification.
              </strong>
              <br />
              You have the right to have your information rectified if that
              information is inaccurate or incomplete.
              <br /> --
              <strong className="block has-text-white">
                {" "}
                The right to object.
              </strong>
              <br />
              You have the right to object to our processing of your Personal
              Data.
              <br /> --
              <strong className="block has-text-white">
                {" "}
                The right of restriction.
              </strong>
              <br />
              You have the right to request that we restrict the processing of
              your personal information.
              <br /> --
              <strong className="block has-text-white">
                {" "}
                The right to data portability.
              </strong>
              <br />
              You have the right to be provided with a copy of the information
              we have on you in a structured, machine-readable and commonly used
              format.
              <br /> --
              <strong className="block has-text-white">
                {" "}
                The right to withdraw consent.
              </strong>
              <br />
              You also have the right to withdraw your consent at any time where
              IJMCM LLC, Inc relied on your consent to process your personal
              information.
            </div>
            <div className="block has-text-white">
              Please note that we may ask you to verify your identity before
              responding to such requests. You have the right to complain to a
              Data Protection Authority about our collection and use of your
              Personal Data. For more information, please contact your local
              data protection authority in the European Economic Area (EEA).
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                Age Limits
              </strong>
            </div>
            <div className="block has-text-white">
              We care about the safety of children. Therefore, the App is not
              directed towards children under the age of 13, they are not
              allowed to register with or use the App, or to disclose any
              personal identifiable information without appropriate parental or
              legal guardian approval.
            </div>
            <div className="block has-text-white">If a child is under the
            age of 13, you must obtain parental consent (or consent from a legal
            guardian) prior to using the App. We do not knowingly contact or
            engage with children under the age of 13 without said parental
            consent (or consent from a legal guardian).</div>

          <div className="block has-text-white">
            If you have reason to believe that a child under the said age has
            provided us with their personal information, please contact us at <text className="block has-text-black">admin@ijmcm.com</text> , and we will endeavor to delete that personal
            information from our databases
          </div>

          <div className="block has-text-white">
            <strong className="block has-text-white is-size-5">
              8. Note for California Residents
            </strong>
          </div>
          <div className="block has-text-white">
            If you are a California resident, please note that under the
            California Consumer Privacy Act of 2018, as amended ("CCPA"), and
            regulations promulgated thereunder, you are entitled to receive
            specific information on how we process your data. You also have
            specific rights to your data, including the right to opt out of the
            sale of your personal information. Please review our
            <a href="https://www.joytunes.com/ccpa" target="_blank">
              {" "}
              Supplementary Privacy Notice for California Residents
            </a>{" "}
            to familiarize yourself with your rights under the CCPA.
          </div>

          <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
              Changes
              </strong>
            </div>
            <div className="block has-text-white">
            This Privacy Policy may be updated from time to time for any reason. We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy here. You should check this page from time to time to ensure that you are happy with any changes, as continued use is deemed approval of all changes.
            </div>

            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
              Your Consent
              </strong>
            </div>
            <div className="block has-text-white">
            By using the App, you are consenting to our processing of your information as set forth in this Privacy Policy now and as amended by us. "Processing,” means using cookies on a computer/hand held device or using or touching information in any way, including, but not limited to, collecting, storing, deleting, using, combining and disclosing information, all of which activities will take place in the United States. If you reside outside the United States your information will be transferred, processed and stored there under United States privacy standards. 
            </div>

            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
              Contact us
              </strong>
            </div>
            <div className="block has-text-white">
            If you have any questions regarding privacy while using the App, or have questions about our practices, please contact us via email at <text className="block has-text-black">admin@ijmcm.com</text>.
</div>
            <div className="block has-text-white">
This policy was last updated on January 25, 2022

            </div>
            </div>
        </div>
      </HeaderFooterWrapperApp>
    </>
  );
};
export default PrivacyPolicyApp;
