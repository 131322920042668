import img from './img/2waylogo.gif';
import AboutUsImage from './img/cranberry.jpg';
const AboutUs = () => {
  return (
    <>
      <section class="hero has-2wayview-bg">
        <div class="hero-body">
          <p class="title has-2wayview-color is-3">
            Game Of Falls
          </p>
          <figure class="image is-128x128" style={{height : 'auto'}} >
            <img src={img}  />
           </figure>
          <p class="subtitle has-text-weight-bold">
            About Us
          </p>
        </div>
      </section>
      <div className="columns">
        <div className="column">
          <div className="container">
              <div className="content p-2">
                <div className="block">
                  <h1 className="has-text-centered has-text-info">Cranberry heights has made connecting with faimity and friends just a click away...</h1>
                </div>
                <div className="block">
                <figure class="image is-3by1">
                  <img src={AboutUsImage} />
                </figure>
                </div>
                <div className="block has-text-centered">
                With Cranberry Heights’ 2Wayview.com, you can have a toll-free virtual visit over a private; secure line with anyone you want. You can hear and see each other as you exchange news about the family, children, school and neighborhood. You can see the new baby or show off that cake you baked. Using exclusive technology, 2Wayview.com harnesses
the power of the Internet in ways that you only dreamed about in the past.
                </div>
                <div className="block has-text-centered">
                  You can also take advantage of our <a target="_blank" href="https://www.2wayview.com/pages/resource_center.htm">resource</a> and <a target="_blank" href="https://www.2wayview.com/pages/clinical_center.htm">clinical centers</a>, where you will get up-to-date information or do research on all kinds of issues related to health and wellness. You can even <a target="_blank" href="2wayview.com/pages/ask_expert.htm">ask an expert</a> or learn more about activities going on.
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default AboutUs;