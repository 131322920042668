/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { useForm } from "react-hook-form";
import config from '../../utils/config';
import SignIn from '../helpers//SignIn';
import SignUpInfoProfileBox from './SignUpInfoProfileBox';
const FacebookApId = config.facebook_app_id;
const GoogleClientId = config.google_client_id;

const GoogleSignUpUrl = config.facility_google_sign_up;
const FacbeookSignUpUrl = config.facility_facebook_sign_up;
const SignUpUrl = config.facility_sign_up;
const checkSignedUp = config.check_if_signed_up;
const GoogleSignInUrl = config.commonLoginWithGoogle;

const OnBoardingSignUpBox = (props) => {
  const history = useHistory();
  const password = useRef({});
  const { register, handleSubmit, watch, errors } = useForm({ mode: 'onChange' });
  const [showFacebookInfo, setfacebookInfo] = useState(false);
  const [showGoogleInfo, setGoolgeInfo] = useState(false);
  const [isSocialLogin, setSocialLogin] = useState(false);
  const [data, setData] = useState(null)
  const [isLoading, setLoading] = useState(false);
  const [isErrorMessage, setErrorMessage] = useState(null)
  const [goToLogin, setGotoLogin] = useState(true)
    const [goToSignUp, setGotoSignUp] = useState(true)
  const [ isFacilityCode, setFacilityCode] = useState(false);
  const { state } = history.location

  let payload = {};

  password.current = watch("password", "");

  const OnFacebookResponse = (res) => {
    if (!res.hasOwnProperty('status')) {
      res.signUpMethod = 'facebook';
      setData(res);
      setfacebookInfo(true);
      setSocialLogin(true)
    }
  }
  const onResponseGoogle = (res) => {
    if (res && res.profileObj && res.profileObj.email) {
      let url = checkSignedUp;
      const email = res.profileObj.email
      const config = {
        method: 'post',
        url,
        headers: {},
        data: { email }
      }
      axios(config)
        .then(function (response) {
          setLoading(false)
        })
        .catch(function (error) {
          var err = error.response.data.message
          setErrorMessage(err + " Logging in...")
          if (error.response.data.message === 'This email already exists.') {
            if (!res.hasOwnProperty('error')) {
              setLoading(true);
              url = GoogleSignInUrl
              payload.idToken = res.tokenId
              HandleLogin()
            }
          }
          setLoading(false)
        })
    }
    if (!res.hasOwnProperty('error')) {
      res.signUpMethod = 'google';
      setData(res);
      setGoolgeInfo(true);
      setSocialLogin(true);
    }

  }

  const HandleLogin = () => {
    console.log(payload)
    var config = {
      method: 'post',
      url: GoogleSignInUrl,
      headers: {},
      data: payload
    };
    axios(config)
      .then(function (response) {
        // console.log(response,'handle login')
        //localStorage.setItem('token', response.data.token);
        if (response.data.auth === false && response.data.message != "User is not registered.") {
          setLoading(false)
          setErrorMessage(response.data.message)
          return
        }
        else if (response.data.auth === false && response.data.message === "User is not registered.") {
          setLoading(false)
          // alert(response.data.message + ", Sign-up to continue")
          showErrorMessage()
          swipeLogin()
          return
        }
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user ? response.data.user :{}));
        setLoading(false)
        history.push("/dashboard");
      })
      .catch(function (error) {
        console.log(error)
        var err = error.response.data.message
        setErrorMessage(err)
        setLoading(false)
      });
  }


  const onSubmit = (form_data) => {
    if (form_data.facilityCode) {
      signUpWithFacilityCode(form_data)
    } else {
      const sign_up_data = {
        form_data,
        isSocialLogin,
        social_data: data
      }
      history.push({
        pathname: '/signUp',
        state: sign_up_data
      })
    }
  }

  const signUpWithFacilityCode = (form_data) => {
    setLoading(true)

    let url;
    let config;

    if (isSocialLogin) {
      if (data.signUpMethod === 'facebook') {
        url = FacbeookSignUpUrl;
        const payload = {
          userId: data.userID,
          accessToken: data.accessToken,
          facilityCode: form_data.facilityCode
        }
        config = {
          method: 'post',
          url: url,
          headers: {},
          data: payload
        }
      } else {
        url = GoogleSignUpUrl;
        const payload = {
          idToken: data.tokenId,
          facilityCode: form_data.facilityCode
        }
        config = {
          method: 'post',
          url,
          headers: {},
          data: payload
        }
      }
    } else {
      url = SignUpUrl;
      const payload = {
        userName: form_data.username,
        email: form_data.email == '' ? null : form_data.email,
        password: form_data.password,
        facilityCode: form_data.facilityCode
      }
      config = {
        method: 'post',
        url,
        headers: {},
        data: payload
      }
    }

    axios(config)
      .then(function (response) {
        setLoading(false)
        // props.history.push({
        //   pathname : '/sign-in'
        // })
        // swipeLogin()
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user ? response.data.user : {}));
        history.push("/dashboard");
      })
      .catch(function (error) {
        var err = error.response.data.message
        setErrorMessage(err)
        setLoading(false)
      });
  }

  const swipeLogin = () => {
    setGotoLogin(false)
  }

  const swipeSignUp = () => {
    setGotoSignUp(false)
  }

  const showProfileInfo = () => {
    if (showGoogleInfo) {
      const { email, name, imageUrl } = data.profileObj;
      return (
        <>
          <SignUpInfoProfileBox
            src={imageUrl}
            email={email}
            name={name}
          />
          <br></br>
        </>
      )
    }
    if (showFacebookInfo) {
      const { name, email } = data;
      const imageUrl = data.picture.data.url;
      return (
        <>
          <SignUpInfoProfileBox
            src={imageUrl}
            email={email}
            name={name}
          />
          <br></br>
        </>
      )
    } else {
      return (
        <>
          {/* <FacebookLogin
            appId={FacebookApId}
            callback={OnFacebookResponse}
            fields="name,email,picture"
            render={renderProps => (
            <button className="button has-facebook-bg is-fullwidth my-1"  onClick={renderProps.onClick}>
              <span className="icon has-text-white	">
                <i className="fab fa-facebook"></i>
              </span>
              <span className="has-text-white	">Sign Up with Facebook </span>
            </button>
            )}
          /> */}
          {/* <GoogleLogin
            clientId={GoogleClientId}
            render={renderProps => (
              <button className="button is-danger is-fullwidth" onClick={() => renderProps.onClick()} >
              <span className="icon">
                <i className="fab fa-google"></i>
              </span>
              <span>Sign Up with Google </span>
              </button>
            )}
            autoLoad={false}
            buttonText="Login"
            onSuccess={onResponseGoogle}
            onFailure={onResponseGoogle}
          /> */}
          {/* <br></br>     */}
          {/* <h5 className="subtitle is-5">Or</h5> */}
          {/* <span className="icon is-large has-text-info">
            <i className="fas fa-ellipsis-h fa-2x"></i>
          </span> */}
        </>
      )
    }
  }

  const hideFields = () => {
    if (data && data.signUpMethod === 'from' || data === null) {
      return (
        <>
          <div className="field">
            <p className="control has-icons-left">
              <input className={`input ${errors.username && 'is-danger'}`} type="text" placeholder="Username" name="username" ref={register({ required: "Username Required" })} />
              <span className="icon is-small is-left">
                <i className="fas fa-user"></i>
              </span>
              {errors.username && <p className="help is-danger">{errors.username.message}</p>}
            </p>
          </div>
          <div className="field">
            <p className="control has-icons-left">
              <input className={`input ${errors.email && 'is-danger'}`} type="email" placeholder="Email" name="email" ref={register({  pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address"
              } })} />
              <span className="icon is-small is-left">
                <i className="fas fa-envelope"></i>
              </span>
              {errors.email && <p className="help is-danger">{errors.email.message}</p>}
            </p>
          </div>
          <div className="field">
            <p className="control has-icons-left">
              <input className={`input ${errors.password && 'is-danger'}`} type="password" placeholder="Password" name="password" ref={register({ required: "Password Required", minLength: { value: 8, message: 'Password too short minimum 8 digit' } })} />
              <span className="icon is-small is-left">
                <i className="fas fa-lock"></i>
              </span>
              {errors.password && <p className="help is-danger">{errors.password.message}</p>}
            </p>
          </div>
          <div className="field">
            <p className="control has-icons-left">
              <input className={`input ${errors.password_repeat && 'is-danger'}`} type="password" placeholder="Confirm Password" name="password_repeat" ref={register(({
                validate: value =>
                  value === password.current || "The Password does not match"
              }))} />
              <span className="icon is-small is-left">
                <i className="fas fa-lock"></i>
              </span>
              {errors.password_repeat && <p className="help is-danger">{errors.password_repeat.message}</p>}
            </p>
          </div>
        </>
      )
    }
  }
  const showErrorMessage = () => {
    if (isErrorMessage) {
      return (
        <div className="message is-success">
          <div className="message-body">{isErrorMessage}</div>
        </div>
      )
    }
  }

  console.log(errors);

  return (
    <>
      {
        goToLogin ?
          <div className="card card-has-custom-width" >
            <header className="card-header">
              <div className="tabs fulltabs is-centered">
                <ul className="has-text-centered">
                  <li className={`${goToLogin ? '' : 'is-active'} `} ><a className="has-background-info has-text-white" onClick={swipeLogin}>Login</a></li>
                  <li className={`${goToLogin ? 'is-active' : ''} `}><a className="has-background-info has-text-white" onClick={swipeSignUp}>Sign Up</a></li>
                </ul>
              </div>
            </header>
            <footer class="card-footer">
              <FacebookLogin
                appId={FacebookApId}
                callback={OnFacebookResponse}
                fields="name,email,picture"
                render={renderProps => (
                  <a className=" card-footer-item has-facebook-bg " onClick={renderProps.onClick}>
                    <span className="icon has-text-white">
                      <i className="fab fa-facebook"></i>
                    </span>
                  </a>
                )}
              />
              <GoogleLogin
                clientId={GoogleClientId}
                render={renderProps => (
                  <a className="card-footer-item has-background-danger has-text-white" onClick={() => renderProps.onClick()} >
                    <span className="icon">
                      <i className="fab fa-google-plus"></i>
                    </span>
                  </a>
                )}
                autoLoad={false}
                buttonText="Login"
                onSuccess={onResponseGoogle}
                onFailure={onResponseGoogle}
              />
            </footer>
          </div>
          : ''
      }
      {
        goToLogin ? <div className="card has-sign-up">
          {/* <header className="card-header">
        <p className="card-header-title">Sign Up</p>
        <div  className="card-header-icon" >
          <a className="has-text-success has-text-weight-semibold	" onClick={redirectToLoginPage}>Login</a>
        </div>
      </header> */}
          <div className="card-content">
            {/* <div className="content has-text-centered"> */}
              {showProfileInfo()}
              {showErrorMessage()}
              {hideFields()}
              <div className="control my-3">
                { 'Do you have a facility code?  '}  
                <label class="radio">
                  <input type="radio" name="foobar" onChange={(e) =>{
                    setFacilityCode(true)
                  }} checked={isFacilityCode}></input>
                  Yes
                </label>
                <label class="radio">
                  <input type="radio" name="foobar" onChange={(e) =>{
                    setFacilityCode(false)
                  }} checked={!isFacilityCode}></input>
                  No
                </label>
              </div>
              { isFacilityCode ?
              <div className="field">
                <p className="control has-icons-left">
                  <input className={`input ${errors.facilityCode && 'is-danger'}`} ref={register({ required: "Facility Code Required" })} type="text" placeholder="Enter Your facility code?" name="facilityCode"  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-digital-tachograph"></i>
                  </span>
                  {errors.facilityCode && <p className="help is-danger">{errors.facilityCode.message}</p>}
                </p>
              </div> : null}
              <div className="column mx-3">
                <a className={`button has-text-white button is-rounded is-medium is-fullwidth is-info cursor-pointer ${isLoading ? 'is-loading' : ''}`} target="_blank" onClick={handleSubmit(onSubmit)}>Next</a>
            </div>
            <div className="column has-text-centered">
              <text>or</text>
            </div>
              <div className="column mx-2 has-text-centered">
                <a className="button has-text-blue is-rounded is-info is-light cursor-pointer is-link is-outlined" target="_blank"  href={`${process.env.REACT_APP_API_BASE__DEV}/signup/facility`}>Sign up as Facility</a>
            </div>
            </div>
          {/* </div> */}
          {/* <footer className="card-footer p-4">
      </footer> */}
        </div> :
          <SignIn

          />
      }
    </>
  );
}
export default OnBoardingSignUpBox