import React from 'react';

const StartGameModal = ({ isActive, handleCancel, handleStart }) => {
    return (
        <div className={`modal modal-fx-fadeInScale is-clipped ${isActive ? 'is-active' : ''}`}>
            <div className="modal-background"></div>
            <div className="modal-content">
                <div className="card">
                    <div className="card-content has-text-centered">
                        <h1 className="is-size-5 has-text-danger"> Starting a new game will delete all your previous results!</h1>
                    </div>
                    <footer class="card-footer ">
                        <a class=" card-footer-item is-danger has-background-primary has-text-dark  " onClick={handleStart}>OK</a>
                        <a class=" card-footer-item is-warning has-background-warning has-text-dark" onClick={handleCancel}>Cancel</a>
                    </footer>
                </div>
            </div>
        </div>
    );
};

export default StartGameModal;