import React from "react";
import {
  BrowserRouter as Router,
  Switch
} from "react-router-dom";
import AboutUs from '../AboutUs';
import Dashboard from '../dashboard/Dashboard';
import GameInterface from '../dashboard/GameInterface';
import FacilityUserLogin from '../facilityUser/FacilityUserLogin';
import FacilityUserResetPassword from '../facilityUser/FacilityUserResetPassword';
import FacilityUserSignUp from '../facilityUser/FacilityUserSignUp';
import SignUp from '../helpers/SignUp';
import Forbidden from '../helpers/Forbidden';
import ResetPassword from '../helpers/ResetPassword';
import SignIn from "../helpers/SignIn";
import HomePage from '../HomePage';
import IndividualUserLogin from '../individualUser/IndividualUserLogin';
import IndividualUserResetPassword from '../individualUser/IndividualUserResetPassword';
import IndividualUserSignUp from '../individualUser/IndividualUserSignUp';
import PirvacyPolicy from '../PrivecyPolicy';
import Terms from '../Terms';
import PrivacyPolicyApp from '../PrivacyPolicyApp';
import TermsApp from '../TermsApp';
import ProtectedRoute from '../routes/ProtectedRoute';
import PublicRoute from '../routes/PublicRoute';
import Subscription from "../dashboard/Subscription";
import ContactUs from "../ContactUs";

const RoutHandler = () => {

  return (
    <Router>
      <Switch>
        <ProtectedRoute component={Dashboard}  path='/Dashboard' />
        <ProtectedRoute component={Subscription}  path='/subscription'  />
        <PublicRoute restricted={true} component={SignIn} path="/sign-in"  />
        <PublicRoute restricted={true} component={PirvacyPolicy} path="/PirvacyPolicy"  />
        <PublicRoute restricted={true} component={Terms} path="/Terms"  />
        <PublicRoute restricted={true} component={PrivacyPolicyApp} path="/PrivacyPolicyApp"  />
        <PublicRoute restricted={true} component={TermsApp} path="/TermsApp"  />
        <PublicRoute restricted={true} component={AboutUs} path="/AboutUs"  />
        <PublicRoute restricted={true} component={ResetPassword} path="/forgotPassword"  />
        <ProtectedRoute component={GameInterface}  path='/gameInterface' />
        <PublicRoute restricted={true} component={FacilityUserLogin} path="/facilityUserlogin"  />
        <PublicRoute restricted={true} component={SignUp} path="/signUp"  />
        <PublicRoute restricted={true} component={FacilityUserSignUp} path="/facilityUserSignup"  />
        <PublicRoute restricted={true} component={FacilityUserResetPassword} path="/facilityUserForgotPassword"  />
        <PublicRoute restricted={true} component={IndividualUserResetPassword} path="/individualUserForgotPassword"  />
        <PublicRoute restricted={true} component={IndividualUserLogin} path="/individualUserLogin"  />
        <PublicRoute restricted={true} component={IndividualUserSignUp} path="/individualUserSignup"  />
        <PublicRoute restricted={true} component={Forbidden} path="/forbidden"  />
        <PublicRoute restricted={true} component={HomePage} path="/" exact />
        <PublicRoute restricted={true} component={ContactUs} path="/ContactUs"  />
      </Switch>
    </Router>
    
  )
}
export default RoutHandler;