/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';


const SignUpInfoProfileBox = ({src, name, email}) => {
 
  return (
    <div className="container">
          <div className="box">
      <article className="media">
        <div className="media-left">
          <figure className="image is-64x64">
            <img src={src} alt="Image" />
          </figure>
        </div>
        <div className="media-content">
          <div className="content">
            <p>
              <strong>Sign Up as</strong> <small>{name}</small>
              <br/>
              <strong>Email</strong> : {email}
            </p>
          </div>  
        </div>
      </article>
    </div>
    </div>
  )
}

export default SignUpInfoProfileBox;