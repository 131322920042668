import moment from 'moment';
import { useHistory } from "react-router-dom";

const Modal = ({isActive, title, OnModalToggle, data}) => {
  const d =  moment(data.user.subscriptionEndDate).format('dddd, MMMM Do YYYY');
  
  let history = useHistory();
  const OnClick = () => {
    history.push('/dashboard');
  } 
  return (
    <>
    <div className={`modal  ${isActive ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
        </header>
        <section className="modal-card-body">
        <div className="block is-primary">
        <text>Thank you for your payment of ${data.user.amountCharge}. Your current subscription ends on {d}</text>
        </div>
        {/* <div className="block">
           <strong>Amount Charge : </strong> $ {data.amountCharge}
        </div>
        <div className="block">
          <strong>Subscription End Date</strong> {d}
         </div> */}
         {/* <div className="block">
          <strong>Log in to continue</strong>
         </div> */}
        </section>
        <footer className="modal-card-foot">
          <button  className="button is-success" onClick={OnClick}>Okay</button>
        </footer>
      </div>
    </div>
    </>
  )
}

export default Modal


