/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import { useState } from 'react';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { useForm } from "react-hook-form";
import config from '../../utils/config';
import ForgotPassword from './ForgotPassword';
import OnBoardingSignUpBox from './OnBoardingSignUpBox';
import { useHistory } from "react-router-dom";

const FacebookApId = config.facebook_app_id;
const GoogleClientId = config.google_client_id;

const GoogleSignInUrl = config.commonLoginWithGoogle;
const FacbeookSignInUrl = config.commonLoginWithFacebook;
const SignInUrl = config.commonLogin;



const SignIn = (props) => {

  const [isLoading, setLoading] = useState(false);
  const [isErrorMessage, setErrorMessage] = useState();
  const { register, handleSubmit, watch, errors } = useForm({ mode: 'onChange' });
  const [goToLogin, setGotoLogin] = useState(false)
  const [myEmail, setEmail] = useState()
  let url = '';
  let payload = {};
  let history = useHistory();
  const onResponseGoogle = (res) => {

    if (!res.hasOwnProperty('error')) {
      setLoading(true);
      url = GoogleSignInUrl
      payload.idToken = res.tokenId
      HandleLogin()
    }
  }
  const OnFacebookResponse = (res) => {
    if (!res.hasOwnProperty('status')) {
      setLoading(true)
      url = FacbeookSignInUrl
      payload.userId = res.userID
      payload.accessToken = res.accessToken
      HandleLogin()
    }
  }
  const onSubmit = (data) => {
    setLoading(true)
    url = SignInUrl
    payload.email = data.email
    payload.password = data.password
    HandleLogin()
  }

  const HandleLogin = () => {
    console.log(payload)
    var config = {
      method: 'post',
      url: url,
      headers: {},
      data: payload
    };
    axios(config)
      .then(function (response) {
        // console.log(response.data.message,'handle login')
        if (response.data.message === "User is not registered.") {
          setLoading(false)
          // alert(response.data.message + ", Sign-up to continue")
          setErrorMessage(response.data.message + "Redirecting to SignUp... ")
          showErrorMessage()
          signUpToContinue()
          return
        }
        if (response.data.auth === false && response.data.message != "User is not registered.") {
          setLoading(false)
          setErrorMessage(response.data.message)
          return
        }

        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user ? response.data.user :{}));
        setLoading(false)
        history.push("/dashboard");
      })
      .catch(function (error) {
        console.log(error)
        var err = error.response.data.message
        setErrorMessage(err)
        setLoading(false)
      });
  }

  const redirectToSignUp = () => {
    props.history.push({
      pathname: '/'
    })
  }

  const swipeLogin = () => {
    // setGotoLogin(true)
    //  console.log("heyyyyyyyyyy")
  }

  const swipeSignUp = () => {
    // if(goToLogin === true){
    // }
    setGotoLogin(true);
    console.log('SignUp')
  }

  const signUpToContinue = () => {
    setTimeout(
      () => setGotoLogin(true),
      5000
    );
  }


  const showErrorMessage = () => {
    if (isErrorMessage) {
      return (
        <div className="message is-danger">
          <div className="message-body">{isErrorMessage}</div>
        </div>
      )
    }
  }


  return (
    <>
      {goToLogin ? <OnBoardingSignUpBox  {...props} /> :
        <>
          {
            goToLogin ? '' : <div className="card card-has-custom-width" >
              <header className="card-header">
                <div className="tabs fulltabs is-centered">
                  <ul className="has-text-centered">
                    <li className={`${goToLogin ? '' : 'is-active'} `} ><a className="has-background-info has-text-white" onClick={swipeLogin}>Login</a></li>
                    <li className={`${goToLogin ? 'is-active' : ''} `}><a className="has-background-info has-text-white" onClick={swipeSignUp}>Sign Up</a></li>
                  </ul>
                </div>
              </header>
              {/* <div className="">
                <p className="has-text-centered p-2">With</p>
              </div> */}
              <footer class="card-footer">
                <FacebookLogin
                  appId={FacebookApId}
                  callback={OnFacebookResponse}
                  fields="name,email,picture"
                  render={renderProps => (
                    <a className="card-footer-item has-facebook-bg " onClick={renderProps.onClick}>
                      <span className="icon has-text-white">
                        <i className="fab fa-facebook"></i>
                      </span>
                    </a>
                  )}
                />
                <GoogleLogin
                  clientId={GoogleClientId}
                  render={renderProps => (
                    <a className="card-footer-item has-background-danger has-text-white" onClick={() => renderProps.onClick()} >
                      <span className="icon">
                        <i className="fab fa-google-plus"></i>
                      </span>
                    </a>
                  )}
                  autoLoad={false}
                  buttonText="Login"
                  onSuccess={onResponseGoogle}
                  onFailure={onResponseGoogle}
                />
              </footer>
            </div>
          }
          <div className="card has-sign-up">

            <div className="card-content">

              {showErrorMessage()}

              <div className="field">
                <p className="control has-icons-left ">
                  <input className={`input ${errors.email && 'is-danger'}`} onChange={e => setEmail(e.target.value)} type="text" placeholder="Username or Email" name="email" ref={register({ required: 'Username or Email' })} />
                  <span className="icon is-small is-left">
                    <i className="fas fa-envelope"></i>
                  </span>
                  {errors.email && <p className="help is-danger">{errors.email.message}</p>}
                </p>
              </div>
              <div className="field">
                <p className="control has-icons-left">
                  <input className={`input ${errors.password && 'is-danger'}`} type="password" placeholder="Password" name="password" ref={register({ required: 'Password Required' })} />
                  <span className="icon is-small is-left">
                    <i className="fas fa-lock"></i>
                  </span>
                  {errors.password && <p className="help is-danger">{errors.password.message}</p>}
                </p>
              </div>
              {/* <a className="helper has-text-info" onClick={redirectToSignUp}> Register here</a> */}
              <br />
              <ForgotPassword myEmail={myEmail} />
              <a className={` is-pulled-right is-info px-5 button  ${isLoading ? 'is-loading' : ''}`} onClick={handleSubmit(onSubmit)}>Login</a>
            </div>

          </div>
        </>
      }
    </>
  );
}

export default SignIn