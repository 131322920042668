/* eslint-disable import/no-anonymous-default-export */

const base = process.env.REACT_APP_API_BASE__DEV;

export default {
  base,
  // facebook_app_id : '227711258850135', // DEV
  facebook_app_id: "365474386343724", // LIVE
  // google_client_id : '574521128364-ablksfdgqo890dcm1fkogt6offpijn89.apps.googleusercontent.com', // DEV
  google_client_id:
    "520949983929-u4ij38u2v1g164pdao24jmkgff2or9s8.apps.googleusercontent.com", // LIVE

  individual_sing_up: base + "/api/v2/user/individualUser/register",
  individual_facebook_sign_up:
    base + "/api/v2/user/individualUser/registerWithFacebook",
  individual_google_sign_up:
    base + "/api/v2/user/individualUser/registerWithGoogle",
  individual_login: base + "/api/v2/individualUser/login",
  individual_forgot_password: base + "/api/v2/individualUser/forgotPassword",
  individual_reset_password: base + "/api/v2/individualUser/resetPassword",
  individual_cancel_subscription:
    base + "/api/v2/user/individualUser/cancelSubscription",
  individual_update_subscription:
    base + "/api/v2/user/individualUser/updateSubscription",

  facility_sign_up: base + "/api/v2/user/facilityUser/register",
  facility_facebook_sign_up:
    base + "/api/v2/user/facilityUser/registerWithFacebook",
  facility_google_sign_up:
    base + "/api/v2/user/facilityUser/registerWithGoogle",

  check_if_signed_up: base + "/api/v2/user/individualUserExists/register",

  commonLogin: base + "/api/v2/user/login",
  commonLoginWithFacebook: base + "/api/v2/user/loginWithFacebook",
  commonLoginWithGoogle: base + "/api/v2/user/loginWithGoogle",
  commonForgotPassword: base + "/api/v2/user/forgotPassword",
  commonResetPassword: base + "/api/v2/user/resetPassword/",

  calculate_price_with_discount:
    base + "/api/v2/user/calculatePriceWithDiscount",
  indivudal_Subscription_price:
    base + "/api/v2/user/getIndividualSubscriptionPrice",

  facility_login: base + "/api/v2/user/login",
  facility_forgot_password: base + "/api/v2/facilityUser/forgotPassword",
  facility_reset_password: base + "/api/v2/facilityUser/resetPassword",

  instructional_video: base + "/files/assets/instructions.mp4",
  great_you_made_the_right_choice:
    base + "/files/assets/videos/great_you_made_the_right_choice.mp4",
  please_choose_the_best_answer_below:
    base + "/files/assets/videos/please_choose_the_best_answer_below.mp4",
  right_you_got_it: base + "/files/assets/videos/right_you_got_it.mp4",
  right_you_got_it_now: base + "/files/assets/videos/right_you_got_it_now.mp4",
  sorry_that_is_not_the_right_answer_please_try_again:
    base +
    "/files/assets/videos/sorry_that_is_not_the_right_answer_please_try_again.mp4",
  sorry_that_still_is_not_the_right_answer_Please_try_again:
    base +
    "/files/assets/videos/sorry_that_still_is_not_the_right_answer_Please_try_again.mp4",
  that_still_is_not_the_best_choice_here:
    base + "/files/assets/videos/that_still_is_not_the_best_choice_here.mp4",
  why_did_you_choose_CYNTHIA_here:
    base + "/files/assets/videos/why_did_you_choose_CYNTHIA_here.mp4",
  why_did_you_choose_SALLY_here:
    base + "/files/assets/videos/why_did_you_choose_SALLY_here.mp4",
  why_did_you_choose_SAM_here:
    base + "/files/assets/videos/why_did_you_choose_SAM_here.mp4",

  getAllGames: base + "/api/v2/user/games",
  getGameById: base + "/api/v2/user/game/",
  saveGame: base + "/api/v2/user/answer",
  getResumeGame: base + "/api/v2/user/answer/",
  getAllAnswersForAllGames: base + "/api/v2/user/answer/all",
  generateReportAllGames: base + "/api/v2/user/answer/generateReport",
  getLastAnswersForAllGames: base + "/api/v2/user/answer",
  getSubscrptionDetails: base + "/api/v2/user//individualUser/getByID",
  contactUs: base + "/api/v1/facility/contactUs",
  getSurveyForAllUser: base + "/api/v2/user/getAllSurveyForUser",
  answerQuestionOfSurvey: base + "/api/v2/user/survey/answers",

  //Feedback
  giveFeedback: base + "/api/v1/feedback/addFeedback",

};
