import HeaderFooterWrapperApp from "./helpers/HeaderFooterWrapperApp";

const TermsApp = () => {
  return (
    <>
      <HeaderFooterWrapperApp>
        <section class="hero has-2wayview-bg columns is-vcentered">
          <div class="hero-body column is-12 is-vcentered">
            <p class="subtitle has-text-weight-bold has-text-centered is-size-3">
              TERMS OF USE
            </p>
          </div>
        </section>
        <div className="container ">
          <div className="content p-5 has-text-left">
            <div className="block has-text-white">
              This page (together with the documents referred to on it) tells
              you the terms of use (the “Terms of Use”) on which you may make
              use of our application for mobile devices (the “App”). Please read
              these Terms of Use carefully before you download, install or use
              the App. By downloading, installing or using the App, you indicate
              that you accept these Terms of Use and the Privacy Policy and you
              agree to abide by them both. Your download, installation or use of
              the App constitutes your acceptance of these Terms of Use and the
              Privacy Policy which takes effect on the date on which you
              download, install or use the App. If you do not agree with these
              Terms of Use and the Privacy Policy, you should cease downloading,
              installing or using the App immediately. You are only authorized
              to use the App if you agree to abide by and do abide by all
              applicable laws, these Terms of Use and the Privacy Policy. We
              require that you review our Privacy Policy and encourage you to
              raise any relevant questions with us.
            </div>
            <div className="block has-text-white">
              The App is operated by IJMCM LLC Inc., a Delaware corporation with
              an office at 152-30 Jewel Ave., Flushing, NY 11367 (and we refer
              to ourselves as “we”, “us” or “our”). We own and operate the App
              on our own behalf.
            </div>
            <div className="block has-text-white">
              We reserve the right to change these Terms of Use at any time
              without notice to you by updating the App to incorporate the new
              terms of use. You are responsible for regularly reviewing the
              Terms of Use. Your continued use of the App after changes are
              incorporated constitutes your acceptance of the amended Terms of
              Use.
            </div>
            <div className="block has-text-white">
              THE APP AND ALL MATERIALS CONTAINED THEREIN ARE DISTRIBUTED AND
              TRANSMITTED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT
              WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
              WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
              PARTICULAR PURPOSE.
            </div>
            <div className="block has-text-white">
              To download, install, access or use the App, you must be 18 years
              of age or over. If you are under 18 and you wish to use download,
              install, access or use the App, you must get consent from your
              parent or guardian before doing so
            </div>
            <div className="block has-text-white">
              IF YOU PROVIDE CONSENT FOR A MINOR TO USE THE APP, YOU AGREE TO BE
              BOUND BY THESE TERMS OF USE IN RESPECT OF THEIR USE
            </div>

            <div className="block has-text-white">
              We operate the software underlying and required for your use of
              the App from the United States of America and it is possible that
              some downloads from the App could be subject to government export
              controls or other restrictions. If you download anything from or
              use the App, you represent that you are not subject to such
              controls or restrictions. We make no representation that anything
              is appropriate, permissible or available for use outside the
              United States, and using the App from territories in which such
              use or the information available from such use is illegal,
              restricted or not permitted, is expressly prohibited. If you
              choose to access or use the App from or in locations outside of
              the United States, you do so on your own initiative and are
              responsible for:
              <br />
              a. ensuring that what you are doing in that country is legal; and
              <br />
              b. the consequences and compliance by you with all applicable
              laws, regulations, byelaws, codes of practice, licenses,
              registrations, permits and authorizations (including any laws that
              relate to businesses providing services). <br />
              c. all access to the App through your mobile device and for
              bringing these Terms of Use to the attention of all such persons.
            </div>

            <div className="block has-text-white">
              Use of the App does not include the provision of a mobile device
              or other necessary equipment to access it. You shall be solely
              responsible for procuring a compatible device if you wish to use
              the App. In addition, to use the App you will require Internet
              connectivity and appropriate telecommunication links. We shall not
              have any responsibility or liability for any telephone or other
              costs you may incur.
            </div>

            <div className="block has-text-white">
              You shall not in any way use the App or submit to us or to the App
              or to any user of the App anything which in any respect:
              <br />
              a. is in breach of any law, statute, regulation or byelaw of any
              applicable jurisdiction; <br />
              b. is fraudulent, criminal or unlawful;
              <br />
              c. is inaccurate or out-of-date; <br />
              d. may be obscene, indecent, pornographic, vulgar, profane,
              racist, sexist, discriminatory, offensive, derogatory, harmful,
              harassing, threatening, embarrassing, malicious, abusive, hateful,
              menacing, defamatory, untrue or political; <br />
              e. impersonates any other person or body or misrepresents a
              relationship with any person or body; <br />
              f. may infringe or breach the copyright or any intellectual
              property rights (including without limitation copyright, trademark
              rights and broadcasting rights) or privacy or other rights of us
              or any third party; <br />
              g. may be contrary to our interests; <br />
              h. is contrary to any specific rule or requirement that we
              stipulate on the App in relation to a particular part of the App
              or the App generally; or <br />
              i.involves your use, delivery or transmission of any viruses,
              unsolicited emails, trojan horses, trap doors, back doors, easter
              eggs, worms, time bombs, cancelbots or computer programming
              routines that are intended to damage, detrimentally interfere
              with, surreptitiously intercept or expropriate any system, data or
              personal information.
            </div>
            <div className="block has-text-white">
              You agree not to reproduce, duplicate, copy or re-sell the App or
              any part of the App save as may be permitted by these Terms of
              Use.
            </div>

            <div className="block has-text-white">
              You agree not to access without authority, interfere with, damage
              or disrupt:
              <br />
              a. any part of the App; <br />
              b. any equipment or network on which the App is stored; <br />
              c. any software used in the provision of the App; or d. any
              equipment or network or software owned or used by any third party.
            </div>

            <div className="block has-text-white">
              Commentary and other materials available on the App are not
              intended to amount to advice on which reliance should be placed.
              Subject to paragraphs Your Representations and Warranties and
              Indemnification provisions below, we therefore disclaim all
              liability and responsibility arising from any reliance placed on
              such materials by any user of the App, or by anyone who may be
              informed of any of its contents.
            </div>

            <div className="block has-text-white">
              You assume sole responsibility for results obtained from the use
              of the App, and for conclusions drawn from such use. We shall have
              no liability for any damage caused by errors or omissions in any
              information, instructions or scripts provided to us by you in
              connection with the App, or any actions taken by us at your
              direction.
            </div>

            <div className="block has-text-white">
              You agree to comply at all times with any instructions for use of
              the App which we make from time to time.
            </div>

            <div className="block has-text-white">
              If you choose, or you are provided with, a user identification
              code, password or any other piece of information as part of our
              security procedures, you must treat such information as
              confidential, and you must not disclose it to any third party. We
              have the right to disable any user identification code or
              password, whether chosen by you or allocated by us, at any time,
              if in our opinion you have failed to comply with any of the
              provisions of these Terms of Use.
            </div>

            <div className="block has-text-white">
              You affirm that you have read and accepted these Terms of Use and
              the Privacy Policy, and that you are fully able and competent to
              enter into and comply with the Terms of Use, conditions,
              obligations, affirmations, representations, and warranties set
              forth herein.
            </div>

            <div className="block has-text-white">
              <strong className="block has-text-white is-size-4">
                Availability of the App, Security & Accuracy
              </strong>
            </div>

            <div className="block has-text-white">
              We make no warranty that your access to the App will be
              uninterrupted, timely or error-free. Due to the nature of the
              Internet, this cannot be guaranteed. In addition, we may
              occasionally need to carry out repairs, maintenance or introduce
              new facilities and functions.
            </div>

            <div className="block has-text-white">
              Access to the App may be suspended or withdrawn to or from you
              personally or all users temporarily or permanently at any time and
              without notice. We may also impose restrictions on the length and
              manner of usage of any part of the App for any reason. If we
              impose restrictions on you personally, you must not attempt to use
              the App under any other name or user or on any other mobile
              device.
            </div>

            <div className="block has-text-white">
              We do not warrant that the App will be compatible with all
              hardware and software which you may use. We shall not be liable
              for damage to, or viruses or other code that may affect, any
              equipment (including but not limited to your mobile device),
              software, data or other property as a result of your download,
              installation, access to or use of the App or your obtaining any
              material from, or as a result of using, the App. We shall also not
              be liable for the actions of third parties.
            </div>

            <div className="block has-text-white">
              We may change or update the App and anything described in it
              without notice to you. If the need arises, we may suspend access
              to the App, or close it indefinitely.
            </div>

            <div className="block has-text-white">
              We make no representation or warranty, express or implied, that
              information and materials on the App are correct, no warranty or
              representation, express or implied, is given that they are
              complete, accurate, up-to-date, fit for a particular purpose and,
              to the extent permitted by law, we do not accept any liability for
              any errors or omissions. This shall not affect any obligation
              which we may have under any contract that we may have with you to
              provide you with products.
            </div>

            <div className="block has-text-white">
              <strong className="block has-text-white is-size-4">
                Independence from Platforms
              </strong>
            </div>

            <div className="block has-text-white">
              The App is independent of any platform on which it is located. The
              App is not associated, affiliated, sponsored, endorsed or in any
              way linked to any platform operator, including, without
              limitation, Apple, Google, Android, RIM Blackberry or any other
              platform operator (each being an “Operator”).
            </div>

            <div className="block has-text-white">
              Your download, installation, access to or use of the App is also
              bound by the terms and conditions of the Operator.
            </div>

            <div className="block has-text-white">
              The license granted to you for the App is limited to a
              non-transferable license to use the App on a mobile device that
              you own or control and as permitted by these Terms of Use.
            </div>

            <div className="block has-text-white">
              We are solely responsible for providing any maintenance and
              support services with respect to the App as required under
              applicable law. You and we acknowledge that an Operator has no
              obligation whatsoever to furnish any maintenance and support
              services with respect to the App.
            </div>

            <div className="block has-text-white">
              In the event of any failure of the App to conform to any
              applicable warranty, you may notify the relevant Operator and that
              Operator will refund the purchase price for the App (if any
              purchase price has been paid) to you; and, to the maximum extent
              permitted by applicable law, that Operator will have no other
              warranty obligation whatsoever with respect to the App, and any
              other claims, losses, liabilities, damages, costs or expenses
              attributable to any failure to conform to any warranty will be our
              sole responsibility.
            </div>

            <div className="block has-text-white">
              You and we acknowledge that we, not the relevant Operator, are
              responsible for addressing any claims of you or any third party
              relating to the App or your possession and/or use of the App,
              including, but not limited to: <br />(i) any claim that the App fails to
              conform to any applicable legal or regulatory requirement; and
              <br />(ii) claims arising under consumer protection or similar
              legislation.
            </div>

            <div className="block has-text-white">
              You and we acknowledge that, in the event of any third party claim
              that the App or your possession and use of the App infringes that
              third party’s intellectual property rights, we, not the relevant
              Operator, will be solely responsible for the investigation,
              defense, settlement and discharge of any such intellectual
              property infringement claim; provided such infringement was caused
              by us.
            </div>

            <div className="block has-text-white">
              You must comply with any applicable third party terms of agreement
              when using the App (e.g. you must ensure that your use of the App
              is not in violation of your mobile device agreement or any
              wireless data service agreement).
            </div>

            <div className="block has-text-white">
              You and we acknowledge and agree that the relevant Operator, and
              that Operator’s subsidiaries, are third party beneficiaries of
              these Terms of Use, and that, upon your acceptance of these Terms
              of Use, that Operator will have the right (and will be deemed to
              have accepted the right) to enforce these Terms of Use against you
              as a third party beneficiary thereof.
            </div>

            <div className="block has-text-white">
              <strong className="block has-text-white is-size-4">
                Limitation of Liability
              </strong>
            </div>

            <div className="block has-text-white">
              You hereby release IJMCM LLC Inc., its officers, directors,
              agents, and employees from all claims, demands, and damages
              (actual and consequential) of any kind and nature, known and
              unknown, suspected and unsuspected, disclosed and undisclosed,
              arising out of, or in any way, connected with any disputes arising
              between you and any suppliers, or between you and other App or
              Website users.
            </div>

            <div className="block has-text-white">
              <strong className="block has-text-white is-size-4">Disclaimers</strong>
            </div>

            <div className="block has-text-white">
              YOU ASSUME ALL RESPONSIBILITY AND RISK WITH RESPECT TO YOUR USE OF
              THE APP. THE APP IS AVAILABLE “AS IS,” AND “AS AVAILABLE”.{" "}
            </div>

            <div className="block has-text-white">
              YOU UNDERSTAND AND AGREE THAT, TO THE FULLEST EXTENT PERMITTED BY
              LAW, WE DISCLAIM ALL WARRANTIES, REPRESENTATIONS AND ENDORSEMENTS,
              EXPRESS OR IMPLIED, WITH REGARD TO THE APP, INCLUDING, WITHOUT
              LIMITATION, IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY,
              NON-INFRINGEMENT AND FITNESS FOR A PARTICULAR PURPOSE.
            </div>

            <div className="block has-text-white">
              WE DO NOT WARRANT USE OF THE APP WILL BE UNINTERRUPTED OR
              ERROR-FREE OR THAT ERRORS WILL BE DETECTED OR CORRECTED. WE DO NOT
              ASSUME ANY LIABILITY OR RESPONSIBILITY FOR ANY COMPUTER VIRUSES,
              BUGS, MALICIOUS CODE OR OTHER HARMFUL COMPONENTS, DELAYS,
              INACCURACIES, ERRORS OR OMISSIONS, OR THE ACCURACY, COMPLETENESS,
              RELIABILITY OR USEFULNESS OF THE INFORMATION DISCLOSED OR ACCESSED
              THROUGH THE APP.
            </div>

            <div className="block has-text-white">
              WE HAVE NO DUTY TO UPDATE OR MODIFY THE APP AND WE ARE NOT LIABLE
              FOR OUR FAILURE TO DO SO.
            </div>

            <div className="block has-text-white">
              IN NO EVENT, UNDER NO LEGAL OR EQUITABLE THEORY (WHETHER TORT,
              CONTRACT, STRICT LIABILITY OR OTHERWISE), SHALL WE OR ANY OF OUR
              RESPECTIVE EMPLOYEES, DIRECTORS, OFFICERS, AGENTS OR AFFILIATES,
              BE LIABLE HEREUNDER OR OTHERWISE FOR ANY LOSS OR DAMAGE OF ANY
              KIND, DIRECT OR INDIRECT, IN CONNECTION WITH OR ARISING FROM THE
              APP, THE USE OF THE APP OR OUR AGREEMENT WITH YOU CONCERNING THE
              APP, INCLUDING, BUT NOT LIMITED TO, COMPENSATORY, DIRECT,
              CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL OR PUNITIVE DAMAGES,
              LOST ANTICIPATED PROFITS, LOSS OF GOODWILL, LOSS OF DATA, BUSINESS
              INTERRUPTION, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR
              MALFUNCTION, EVEN IF WE HAVE BEEN ADVISED OF OR SHOULD HAVE KNOWN
              OF THE POSSIBILITY OF SUCH DAMAGES. IF WE ARE HELD LIABLE TO YOU
              IN A COURT OF COMPETENT JURISDICTION FOR ANY REASON, IN NO EVENT
              WILL WE BE LIABLE FOR ANY DAMAGES IN EXCESS OF ONE HUNDRED FIFTY
              DOLLARS (US$150.00). SOME JURISDICTIONS DO NOT ALLOW THE
              LIMITATION OR EXCLUSION OF LIABILITY FOR CONSEQUENTIAL OR
              INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT
              APPLY TO YOU.
            </div>

            <div className="block has-text-white">
              IF ANY LIMITATION ON REMEDIES, DAMAGES OR LIABILITY IS PROHIBITED
              OR RESTRICTED BY LAW, WE SHALL REMAIN ENTITLED TO THE MAXIMUM
              DISCLAIMERS AND LIMITATIONS AVAILABLE UNDER THIS AGREEMENT, AT LAW
              AND/OR IN EQUITY.
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-4">
                Your Representations and Warranties
              </strong>
            </div>
            <div className="block has-text-white">
              You represent and warrant that: <br />(a) your use of the App will be in
              strict accordance with this Agreement and with all applicable laws
              and regulations, including without limitation any local laws or
              regulations in your country, state, city, or other governmental
              area, regarding online conduct and acceptable content, and
              regarding the transmission of technical data exported from the
              United States or the country in which you reside and <br />(b) your use
              of the App will not infringe or misappropriate the intellectual
              property rights of any third party
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-4">Indemnification</strong>
            </div>
            <div className="block has-text-white">
              You agree to indemnify and hold IJMCM LLC, and each of our
              affiliates, successors and assigns, and their respective officers,
              directors, employees, agents, representatives, licensors,
              advertisers, suppliers, and operational service providers harmless
              from and against any and all losses, expenses, damages, costs and
              expenses (including attorneys’ fees), resulting from your use of
              the App and/or any violation of the terms of this Agreement. We
              reserve the right to assume the exclusive defense and control of
              any demand, claim or action arising hereunder or in connection
              with the App and all negotiations for settlement or compromise.
              You agree to fully cooperate with us in the defense of any such
              demand, claim, action, settlement or compromise negotiations, as
              requested by us.
            </div>
            <div className="block has-text-white">
              You agree that the provisions in this paragraph will survive any
              termination of your use of the App.
            </div>
            <div className="block has-text-white">Trade Marks</div>
            <div className="block has-text-white">
              The “Game of Falls” name and logos and all related names,
              trademarks, service marks, design marks and slogans are the
              trademarks or service marks of us.
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-4">
                Intellectual Property Rights
              </strong>
            </div>
            <div className="block has-text-white">
              All content, information, photographs, illustrations, artwork and
              other graphic materials, and names, logos and trade marks on the
              App are protected by copyright laws and/or other laws and/or
              international treaties, and belong to us and/or our suppliers, as
              the case may be. These works, logos, graphics, sounds or images
              may not be copied, reproduced, retransmitted, distributed,
              disseminated, sold, published, broadcasted or circulated whether
              in whole or in part, unless expressly permitted by us and/or our
              suppliers, as the case may be
            </div>
            <div className="block has-text-white">
              As between you and us, we are the sole and exclusive owner or the
              licensee of all intellectual property rights in the App, and in
              the material published on it. Those works are protected by
              copyright and trademark laws and treaties around the world. All
              such rights are reserved.
            </div>
            <div className="block has-text-white">
              You may print off one copy, and may download extracts, of any
              page(s) from the App for your personal reference and you may draw
              the attention of others within your organization to material
              available on the App.
            </div>
            <div className="block has-text-white">
              You must not modify the paper or digital copies of any materials
              you have printed off or downloaded in any way, and you must not
              use any illustrations, photographs, video or audio sequences or
              any graphics separately from any accompanying text.
            </div>
            <div className="block has-text-white">
              You must not use any part of the materials on the App for
              commercial purposes without obtaining a license to do so from us
              or our licensors.
            </div>
            <div className="block has-text-white">
              If you print off, copy or download any part of the App in breach
              of these Terms of Use, your right to use the App will cease
              immediately and you must, at our option, return or destroy any
              copies of the materials you have made.
            </div>
            <div className="block has-text-white">
              We will not hesitate to take legal action against any unauthorized
              usage of our trade marks, name or symbols to preserve and protect
              its rights in the matter. All rights not expressly granted herein
              are reserved. We will not hesitate to take legal action against
              any unauthorized usage of our trade marks, name or symbols to
              preserve and protect its rights in the matter. All rights not
              expressly granted herein are reserved.
            </div>
            <div className="block has-text-white">
              YOU ACKNOWLEDGE AND AGREE THAT NOTHING IN THESE TERMS OF USE SHALL
              HAVE THE EFFECT OF TRANSFERRING THE OWNERSHIP OF ANY COPYRIGHTS,
              TRADEMARKS, SLOGANS, SERVICE MARKS, TRADE NAMES, TRADE DRESS OR
              OTHER PROPRIETARY RIGHTS IN THE SITE OR CONTENT OR ANY PART
              THEREOF TO YOU OR ANY THIRD PARTY.
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-4">
                Information About You & Your Use of the App
              </strong>
            </div>
            <div className="block has-text-white">
              We process information about you in accordance with our Privacy
              Policy. By using the App, you consent to such processing and you
              warrant that all data provided by you is accurate.
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-4">
                Third Party Websites
              </strong>
            </div>
            <div className="block has-text-white">
              We have no control over and accept no responsibility for the
              content of any website or mobile application to which a link from
              the App exists (unless we are the provider of those linked
              websites or mobile applications). Such linked websites and mobile
              applications are provided “as is” for your convenience only with
              no warranty, express or implied, for the information provided
              within them. We do not provide any endorsement or recommendation
              of any third party website or mobile application to which the App
              provides a link. The terms and conditions, terms of use and
              privacy policies of those third party websites and mobile
              applications will apply to your use of those websites and mobile
              applications and any orders you make for goods and services via
              such websites and mobile applications. If you have any queries,
              concerns or complaints about such third party websites or mobile
              applications (including, but not limited to, queries, concerns or
              complaints relating to products, orders for products, faulty
              products and refunds) you must direct them to the operator of that
              third party website or mobile application.
            </div>
            <div className="block has-text-white">
              You must not without our permission: <br />a) use or copy any material
              from the App, including, but not limited to, onto other websites
              or in other mobile applications; or <br />b) frame any of the App onto
              your own or another person’s website or mobile application.
              Severability
            </div>
            <div className="block has-text-white">
              If any of these terms should be determined to be illegal, invalid
              or otherwise unenforceable by reason of the laws of any state or
              country in which these terms are intended to be effective, then to
              the extent and within the jurisdiction which that term is illegal,
              invalid or unenforceable, it shall be severed and deleted and the
              remaining Terms of Use shall survive, remain in full force and
              effect and continue to be binding and enforceable.
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-4">Non-assignment</strong>
            </div>
            <div className="block has-text-white">
              You shall not assign or transfer or purport to assign or transfer
              the contract between you and us to any other person.
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-4">Exclusion</strong>
            </div>
            <div className="block has-text-white">
              Except as expressly stated in these Terms of Use, all warranties
              and conditions, whether express or implied by statute, common law
              or otherwise are hereby excluded to the extent permitted by law.
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-4">
                Conflicting Documents
              </strong>
            </div>
            <div className="block has-text-white">
              To the extent that these Terms of Use conflict with the Privacy
              Policy or any other IJMCM LLC Terms of Use or policies, these
              Terms of Use shall govern.
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-4">Miscellaneous</strong>
            </div>
            <div className="block has-text-white">
              These Terms of Use (and our Privacy Policy and any other document
              referred to in these Terms of Use and any other terms and
              conditions specifically agreed between you and us in writing)
              contain all the terms agreed between us and you regarding their
              subject matter and supersedes and excludes any prior terms and
              conditions, understanding or arrangement between us and you,
              whether oral or in writing.
            </div>
            <div className="block has-text-white">
              No representation, undertaking or promise shall be taken to have
              been given or be implied from anything said or written in
              negotiations between us and you prior to these Terms of Use except
              as expressly stated in these Terms of Use.
            </div>
            <div className="block has-text-white">
              Neither us nor you shall have any remedy in respect of any untrue
              statement made by the other upon which that party relied in
              entering into these Terms of Use (unless such untrue statement was
              made fraudulently or was as to a matter fundamental to a party’s
              ability to perform these Terms of Use) and that party’s only
              remedies shall be for breach of contract as provided in these
              Terms and Conditions.
            </div>
            <div className="block has-text-white">
              These Terms of Use may only be modified by a written amendment
              signed by an authorized executive of the IJMCM LLC Inc or by the
              posting of a revised version by us.
            </div>
            <div className="block has-text-white">
              Except to the extent applicable law, if any, provides otherwise,
              these Terms and any access to or use of the App will be governed
              by the laws of the State of New York, excluding its conflict of
              law provisions.
            </div>
            <div className="block has-text-white">
              Any dispute or claim arising out of or in connection with these
              Terms of Use will be subject to the exclusive jurisdiction of the
              federal and state courts sitting in the County of Queens.
            </div>
            <div className="block has-text-white">
              All dealings, correspondence and contacts between us shall be made
              or conducted in the English language.
            </div>
            <div className="block has-text-white">
              If any part of these Terms of Use is held invalid or
              unenforceable, that part will be construed to reflect the parties’
              original intent, and the remaining portions will remain in full
              force and effect.
            </div>
            <div className="block has-text-white">
              A waiver by either party of any term or condition of these Terms
              of Use or any breach thereof, in any one instance, will not waive
              such term or condition or any subsequent breach thereof.
            </div>
            <div className="block has-text-white">
              You may not assign your rights under these Terms of Use to any
              party; We may assign our rights under these Terms without
              condition.
            </div>
            <div className="block has-text-white">
              These Terms of Use will be binding upon and will inure to the
              benefit of the parties, their successors, and permitted assigns.
            </div>
            <div className="block has-text-white">
              These Terms of Use were most recently updated on January 22, 2022.
            </div>
          </div>
        </div>
      </HeaderFooterWrapperApp>
    </>
  );
};
export default TermsApp;
