import React from 'react';
import {
    BrowserRouter as Router,
    Link
  } from "react-router-dom";

const Panel = ({title, subTitle, subTitleLink, children}) => {
    return (
      <div className="panel">
        <div className="panel-heading">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h2 className="subtitle">{title}</h2>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <Link to={subTitleLink} className="button">{subTitle}</Link>
              </div>
            </div>
          </div>
        </div>
          <div className="panel-block is-clearfix">
            {children}
          </div>
      </div>
    )
}

export default Panel;




