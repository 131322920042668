import Confetti from 'react-confetti';
import ReactPlayer from 'react-player';
import videoUrl from '../../assets/EndOfGame.mp4';

const PopUpFinal = ({isActive, onFinalEnded}) => {

  var height = window.innerHeight
  var width = window.innerWidth

  return (
    <div className={`modal modal-fx-fadeInScale is-clipped ${isActive ? 'is-active' : ''}`}>
      <Confetti
      width={width}
      height={height}
      />
      <div className="modal-background"></div>
      <div className="modal-content">
        <ReactPlayer 
          width="100%"
          height="100%"
          playing={isActive}
          url={videoUrl}
          onEnded={onFinalEnded}
        />
      </div>
      {/* <button    className="modal-close is-large" aria-label="close" onClick={toggleActive}></button> */}
    </div>
  )
}

export default PopUpFinal;