import React from 'react';
import HeaderFooterWrapper from '../helpers/HeaderFooterWrapper';
import Login from '../helpers/Login';
import Panel from '../helpers/Panel';
import FacilityUserForgotPassword from './FacilityUserForgotPassword';

const FacilityUserLogin = () => {
  return (
    <HeaderFooterWrapper>
      <div className="columns  py-3">
        <div className="column is-three-fifths is-offset-one-fifth mt-2">
          <Panel 
            title="Login"
            subTitle="Sign Up"
            subTitleLink="/facilityUserSignUp"
            >
            <Login />
            <br/>
            <br/>
            <FacilityUserForgotPassword />
          </Panel>
        </div>
      </div>
    </HeaderFooterWrapper>
  )
}

export default  FacilityUserLogin ;