import img from "./img/2waylogo.gif";
import HeaderFooterWraper from "./helpers/HeaderFooterWrapper";
import axios from "axios";
import React, {useState} from "react";
import config from "../utils/config"

var ContactUsUrl = config.contactUs;

const ContactUs = () => {

    const [isLoading, setLoading] = useState(false);
    const [isShowMessage,setShowMessage] = useState("");
    const [isSuccess,setSuccess] = useState(false);
    const [isError,setError] = useState(false)
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
   
    const resetForm = () => {
        setName("");
        setEmail("");
        setMessage("");
    }

    const showMessage = (response) => {
    
        if (isSuccess) {
            return (
              <div className="message is-success">
                <div className="message-body">{response}</div>
              </div>
            )
        } else if (isError) {
            return (
                <div className="message is-danger">
                  <div className="message-body">{response}</div>
                </div>
              )
        }

    }  

    
   const handleSubmit = (e) =>{
        e.preventDefault();
        setLoading(true)
        
        axios({
          method: "POST",
          url:ContactUsUrl,
          data: {name:name , email :email , issue: message}
        }).then((response)=>{

          if (response.status === 200) {
            setLoading(false)
            console.log(response.data.message)
            setShowMessage(response.data.message)
            setSuccess(true)
            showMessage(response.data.message)
            resetForm();
            return
            
          } else if (response.status !== 200 ) {
            setLoading(false)
            setShowMessage(response.data.message)
            setError(true)
            showMessage(response.data.message)
          }
          

        }).catch((error)=>{
            console.log(error);
            setLoading(false)
            setShowMessage(error.message)
            setError(true)
            showMessage(error.message);
        })
        
      }
    
    const onNameChange = (event) => {
        setName(event.target.value);
    }
  
    const onEmailChange = (event) => {
        setEmail(event.target.value);
    }
  
    const onMessageChange = (event) => {
        setMessage(event.target.value);
    }



return(
<>
    <HeaderFooterWraper>
  <div className="columns is-centered">
    <div className="column is-half mt-5 ">

    <div className="card my-5 mt-5 mx-5 px-5">
    <div className="card-content">  
    <h2 className="title is-3 is-capitalized has-text-centered ">Contact Us</h2>
    {showMessage(isShowMessage)}
    <form  onSubmit={handleSubmit} method="post">
        <div className="field">
           <label for="name" className="label is-size-6 has-text-weight-light  "></label>
            <div className="control has-icons-left">
                <input type="text" name="name" id="name" className="input" value={name}  onChange={onNameChange}  placeholder="Name" required autoFocus />
                    <span className="icon is-small is-left">
                        <i className="fa fa-user"></i>
                    </span>
            </div>
        </div>
        <div className="field">
            <label for="email" className="label is-size-6 has-text-weight-light  "></label>
            <div className="control has-icons-left">
                <input type="email" name="email" id="email" class="input" value={email} onChange={onEmailChange} placeholder="Email" required />
                    <span className="icon is-small is-left">
                        <i className="fa fa-envelope"></i>
                    </span>
            </div>
        </div>
        <div className="field">
            <label for="message" className="label is-size-6 has-text-weight-light "></label>
            <textarea name="message" id="message" rows="5" className="textarea is-small" value={message} onChange={onMessageChange}  placeholder="Message" required></textarea>
        </div>
        <button type="submit" className={`button is-info  ${isLoading ? 'is-loading' : ''} `}>Submit</button>
    </form>
    </div>
</div>
</div>
</div>
    </HeaderFooterWraper>
</>
);


};

export default ContactUs;