/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import {
  Link
} from "react-router-dom";
import brandImage from '../img/Fall-prevention-logo-white.svg';

const Header = () => {
  const [isActiveNav, setActiveNav] = useState(false);

  const toggleMenuBurger = () => {
    setActiveNav(!isActiveNav);
  }
  return (
    <nav className="navbar  has-background-primary has-text-white" role="navigation" aria-label="main navigation">
      <div className="container">
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            <figure class="image ">
              <img src={brandImage}  />
            </figure>
          </a>
           <a role="button" onClick={() => toggleMenuBurger() } className={`navbar-burger  ${isActiveNav ? 'is-active' : ''} `} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        </a> 
        </div>
        <div id="navbarBasicExample" className={`navbar-menu has-background-primary ${isActiveNav ? 'is-active' : ''} `}>
          {/* <div class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link button is-light" >
          Privacy Policy
        </a>

        <div class="navbar-dropdown">
        <Link class="navbar-item" to="/PirvacyPolicy">
          Privacy Policy
        </Link>
          <Link class="navbar-item" to="AboutUs">
            About us
          </Link>
          <a class="navbar-item">
            Contact us
          </a>
          <hr class="navbar-divider" />
          <div class="navbar-item">
            Version 1.0.0
          </div>
        </div>
      </div> */}
          <div className="navbar-end">
            <div className="navbar-item ">
              <Link class="ml-3 navbar-item has-text-link-light has-background-link is-light" to="/PirvacyPolicy">
                Privacy Policy
              </Link>
              <Link class="ml-3 navbar-item has-text-link-light has-background-link is-light" to="/Terms">
                Terms
              </Link>
              <Link class="ml-3 navbar-item has-text-link-light has-background-link is-light" to="/ContactUs">      
                Contact Us
              </Link>
              {/* <Link class="navbar-item has-text-black" to="AboutUs">
            About us
          </Link> */}
            </div>
          </div>
        </div>
      </div>
    </nav>
  )

}



export default Header;



{/* <div className="buttons">
<a className="button is-primary  has-text-dark" target="_blank" href="https://play.google.com/store/apps/details?id=com.sjinnovation.gameoffalls.live&hl=en&gl=US">
  <span className="icon">
    <i className="fab fa-google-play"></i>
  </span>
  <span>Download Android App </span>
</a>
<a className="button is-primary has-text-dark" target="_blank" href="https://apps.apple.com/us/app/game-of-falls/id1455203161">
<span className="icon">
  <i className="fab fa-app-store-ios"></i>
</span>
<span>Download IOS App</span>
</a>
</div> */}