import ReactPlayer from 'react-player';
import config from '../../utils/config';

const videoUrl = config.right_you_got_it;
const CorrectModal_01 = ({isActive, onEndedCorrectModal_01, handleContinue, handlePause}) => {

  return (
    <div className={`modal is-clipped ${isActive ? 'is-active' : ''}`}>
      <div   className="modal-background"></div>
      <div    className="modal-content">
        <div className="card">
        <div className="card-content">
        <ReactPlayer 
          width="100%"
          height="100%"
          playing={isActive}
          url={videoUrl}
          onEnded={onEndedCorrectModal_01}
        />
        </div>
         {/* <footer class="card-footer ">
            <a  class=" card-footer-item is-danger has-background-primary has-text-dark  " onClick={handleContinue}>Continue</a>
            <a  class=" card-footer-item is-warning has-background-warning has-text-dark" onClick={handlePause}>Pause Playing</a>
          </footer> */}
        </div>
      </div>
      {/* <button    className="modal-close is-large" aria-label="close"></button> */}
     
    </div>
  )
}

export default CorrectModal_01;