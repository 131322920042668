import axios from 'axios';
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import config from '../../utils/config';
import HeaderFooterWraper from './HeaderFooterWrapper';
import Modal from './Modal';
import SignUpInfoProfileBox from './SignUpInfoProfileBox';
import { useHistory } from "react-router-dom";

const GoogleSignUpUrl = config.individual_google_sign_up;
const FacbeookSignUpUrl = config.individual_facebook_sign_up;
const SignUpUrl = config.individual_sing_up;
const CalculatePriceWithDiscount = config.calculate_price_with_discount;
const IndividualSubscriptionPrice = config.indivudal_Subscription_price;


const SignUp = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false)
  const { register, handleSubmit, watch, errors } = useForm({ mode: 'onChange' });
  const [isErrorMessage, setErrorMessage] = useState(null)
  const [subscriptionMesssage, setSubscriptiponMessage] = useState(null);
  const location = useLocation();
  const { state } = location;
  const [discountCode, setDiscountCode] = useState();
  const [isDiscountMessage, setDiscountMessage] = useState();
  const [isDisccountError, setDiscountError] = useState();
  const [discountData, setDiscountData] = useState();
  const [discountLoading, setDiscountLoading] = useState(false);
  const [hideCardForm, setHideCardForm] = useState(false);
  const [price, setPrice] = useState(null);
  const history = useHistory();


  let url;
  let payload = {};

  const OnModalToggle = () => {
    setModalOpen(!isModalOpen);
  }

  const goToHomeScreen = () => {
    history.push('/')
  }
  useEffect(() => {
    const timeOutId = setTimeout(() => fetchDiscountCalculation(discountCode), 1000);
    return () => clearTimeout(timeOutId);
  }, [discountCode]);

  useEffect(() => {
    fetchPrice()
  }, [])

  const fetchPrice = () => {
    const config = {
      method: 'get',
      url: IndividualSubscriptionPrice,
      headers: {},
    }
    axios(config)
      .then(function (response) {
        setPrice(response.data.price);
      })
      .catch(function (error) {
        var err = error.response.data.message
      });
  }
  const fetchDiscountCalculation = (discountCode) => {
    setDiscountLoading(true)
    const config = {
      method: 'post',
      url: CalculatePriceWithDiscount,
      headers: {},
      data: {
        discountCode
      }
    }
    axios(config)
      .then(function (response) {
        setDiscountError()
        setDiscountData(response.data)
        setDiscountLoading(false)
        if (response.data.discountPercentage === 100) {
          setHideCardForm(true)
        }
      })
      .catch(function (error) {
        var err = error.response.data.message
        setDiscountError(err)
        setDiscountData()
        setDiscountLoading(false)
      });
  }
  const onSubmit = (data) => {
    setLoading(true)
    if (hideCardForm) {
      payload.number = "4242424242424242";
      payload.expMonth = 12;
      payload.expYear = 2022;
      payload.cvc = "123";
    }
    else {
      payload.number = data.number;
      payload.expMonth = data.expMonth;
      payload.expYear = data.expYear;
      payload.cvc = data.cvc;
    }
    if (data.discountCode) {
      payload.discountCode = data.discountCode;
    }
    payload.email = payload.email == "" ? null : payload.email;
    const config = {
      method: 'post',
      url,
      headers: {},
      data: payload
    }
    axios(config)
      .then(function (response) {
        setLoading(false)
        setSubscriptiponMessage(response.data)
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user ? response.data.user : {}));
        setModalOpen(true)
      })
      .catch(function (error) {
        var err = error.response.data.message + " Please login to continue. Redirecting to login..."
        setErrorMessage(err)
        setLoading(false)
        setTimeout(
          () => history.push('/'),
          4000
        );
      });
  }
  const getComponentBasedOnForm = () => {
    if (state.isSocialLogin) {
      if (state.social_data.signUpMethod === 'facebook') {
        const { name, email } = state.social_data;
        const imageUrl = state.social_data.picture.data.url;
        url = FacbeookSignUpUrl;
        payload.userId = state.social_data.userID;
        payload.accessToken = state.social_data.accessToken;
        return (
          <>
            <SignUpInfoProfileBox
              src={imageUrl}
              email={email}
              name={name}
            />
            {paymentForm()}
          </>
        )
      } else {
        const { name, email, imageUrl } = state.social_data.profileObj;
        url = GoogleSignUpUrl;
        payload.idToken = state.social_data.tokenId;
        return (
          <>
            <SignUpInfoProfileBox
              src={imageUrl}
              email={email}
              name={name}
            />
            {paymentForm()}
          </>
        )
      }
    } else {
      const { password, email, username } = state.form_data;
      url = SignUpUrl;
      payload.userName = username
      payload.email = email;
      payload.password = password;
      return (
        <>
        {
          email === '' ? null : 
        
          <fieldset  >
            <div className="field-body">
              <div className="field">
                <label className="label">Email</label>
                <div className="control">
                  <input className="input  " type="text" placeholder="" value={email} readOnly />
                </div>
              </div>
            </div>
          </fieldset>
        }
          {paymentForm()}
        </>
      )
    }
  }
  const paymentForm = () => {
    return (
      <>
        <br></br>
        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field">
              <p className={`control has-icons-left  ${discountLoading ? 'is-loading' : ''} `}>
                <input
                  className="input "
                  type="text"
                  placeholder="Discount Code"
                  onChange={e => setDiscountCode(e.target.value)}
                  name="discountCode"
                  ref={register}
                  disabled={discountLoading ? true : false}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-percentage"></i>
                </span>
                {isDisccountError && <p className="help is-danger">{isDisccountError}</p>}
              </p>
            </div>
            <div className="field">
              <div className="control">
                <input className="input" type="text" value={`Total Amount : $ ${discountData ? discountData.totalPrice : price + '.00'} `} readonly disabled />
                {discountData && <p className="help is-success has-text-weight-bold">You got {discountData.discountPercentage}% discount</p>}
              </div>
            </div>
          </div>
        </div>
        {
          hideCardForm ?
            null :
            <div>
              <div className="field">
                <p className="control has-icons-left">
                  <input className={`input ${errors.number && 'is-danger'}`} type="number" pattern="\d*" placeholder="Card Number" name="number" ref={register({ required: "Credit Card number required", minLength: { value: 16, message: 'Card number minimum 16 digit' }, maxLength: { value: 16, message: 'Card number maximum 16 digit' } })} />
                  <span className="icon is-small is-left">
                    <i className="far fa-credit-card"></i>
                  </span>
                  {errors.number && <p className="help is-danger">{errors.number.message}</p>}
                </p>
              </div>
              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <p className="control is-expanded has-icons-left has-icons-right">
                      <input className={`input ${errors.expMonth && 'is-danger'}`} min='1' max="12" type="number" placeholder="Exp. Month" name="expMonth" ref={register({
                        required: 'Exp. Month is Required.',
                        validate: {
                          positive: value => parseInt(value, 10) > 0 || 'should be greater than 0',
                          lessThanTen: value => parseInt(value, 10) < 13 || 'should be lower than 13',
                        }
                      })} />
                      <span className="icon is-small is-left">
                        <i className="fas fa-calendar-week"></i>
                      </span>
                      <span className={`icon is-small is-right ${!errors.expMonth ? 'has-text-success' : 'has-text-danger'}`}>
                        <i className="fas fa-check"></i>
                      </span>
                      {errors.expMonth && <p className="help is-danger">{errors.expMonth.message}</p>}
                    </p>
                  </div>
                  <div className="field">
                    <p className="control is-expanded has-icons-left has-icons-right">
                      <input className={`input ${errors.expYear && 'is-danger'}`} type="number" placeholder="Exp. Year" name="expYear" ref={register({
                        required: 'Exp. year is required.',
                        validate: {
                          positive: value => parseInt(value, 10) >= 2021 || 'should be greater than 2021',
                          lessThanTen: value => parseInt(value, 10) < 2041 || 'should be lower than 2040',
                        }
                      })}
                        max="2040"
                        min="2021"
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-calendar-week"></i>
                      </span>
                      <span className={`icon is-small is-right ${!errors.expYear ? 'has-text-success' : 'has-text-danger'}`}>
                        <i className="fas fa-check"></i>
                      </span>
                      {errors.expYear && <p className="help is-danger">{errors.expYear.message}</p>}
                    </p>
                  </div>
                  <div className="field">
                    <p className="control is-expanded has-icons-left has-icons-right">
                      <input className={`input ${errors.cvc && 'is-danger'}`} type="number" placeholder="CVC" name="cvc" ref={register({ required: 'CVC is required', minLength: 3, maxLength: 3 })} />
                      <span className="icon is-small is-left">
                        <i className="fas fa-credit-card"></i>
                      </span>
                      <span className={`icon is-small is-right ${!errors.cvc ? 'has-text-success' : 'has-text-danger'}`}>
                        <i className="fas fa-check"></i>
                      </span>
                      {errors.cvc && <p className="help is-danger">{errors.cvc.message}</p>}
                    </p>
                  </div>
                </div>
              </div>
            </div>


        }
      </>
    )
  }
  const showErrorMessage = () => {
    if (isErrorMessage) {
      return (
        <div className="message is-danger">
          <div className="message-body">{isErrorMessage}</div>
        </div>
      )
    }
  }
  return (
    <HeaderFooterWraper>
      <div className="columns">
        <div className="column">
          <div className="container">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">Billing</p>
                <div className="card-header-icon" onClick={goToHomeScreen}>
                  <span className="icon">
                    <text>X</text>
                    {/* <i className="fas fa-cancel" style={{'cursor' : 'default'}} aria-hidden="true" ></i> */}
                  </span>
                </div>
              </header>
              <div className="card-content">
                {isModalOpen &&
                  <Modal
                    isActive={isModalOpen}
                    title={'Payment Info'}
                    OnModalToggle={OnModalToggle}
                    data={subscriptionMesssage}
                  />
                }
                {showErrorMessage()}
                {getComponentBasedOnForm()}
              </div>
              <footer className="card-footer p-4">
                <button
                  type="submit"
                  className={`card-footer-item is-primary button is-ghost ${isLoading ? 'is-loading' : ''}`}
                  onClick={handleSubmit(onSubmit)}
                >Complete Sign Up
                </button>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </HeaderFooterWraper>
  )
}
export default SignUp;


// {getInfoBasedOnSignUpMethods()}
// <div className="field">
// <label className="checkbox">
//   <input type="checkbox" />
//    I have 
// </label>
// </div>