import React from "react";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
const QuestionOptions = ({ handleQuestionAnswer, item, questionId }) => {
  return (
    <div>
      <FormControlLabel
        key={item.id}
        id={item.id}
        value={item.id}
        onChange={() => {
          handleQuestionAnswer(questionId, item.option);
        }}
        control={<Radio />}
        label={item.option}
      />
    </div>
  );
};

export default QuestionOptions;
