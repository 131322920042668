import React, { useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import brandImage from '../img/l.svg';
import config from '../../utils/config';
import axios from 'axios';

const DashboardHeader = () => {
  const [isActiveNav, setActiveNav] = useState(false);
  const generateReportAllGames = config.generateReportAllGames;
  let history = useHistory()
  const toggleMenuBurger = () => {
    setActiveNav(!isActiveNav);
  }
 
  let user = JSON.parse(localStorage.getItem('user'));

  const handleLogOut = () => {
    localStorage.clear();
    return window.location.reload();
  }
  
  const generateReport = () => {
    console.log(generateReportAllGames);
    let token = localStorage.getItem('token');
    var config = {
      method: 'get',
      url: generateReportAllGames,
      headers: { 'Authorization': 'Bearer ' + token },
    };

    axios(config).then(function (response) {
      window.open(process.env.REACT_APP_API_BASE__DEV + response.data.report, "_blank");
    }).catch(function (error) {
      console.log(error);
    });
  }

  return (
    <>
    <nav className="navbar  has-background-primary has-text-white" role="navigation" aria-label="main navigation">
      <div className="container">
      <div className="navbar-brand">
        <Link to="/dashboard"><a className="navbar-item" href="/dashboard"> 
          <figure class="image ">
            <img src={brandImage} width="112" height="28"/>
          </figure>
        </a>
        </Link>
        <a role="button" onClick={() => toggleMenuBurger() } className={`navbar-burger  ${isActiveNav ? 'is-active' : ''} `} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        </a>
      </div>
      <div id="navbarBasicExample" className={`navbar-menu has-background-primary ${isActiveNav ? 'is-active' : ''} `}>
        <div className="navbar-end">
          <div className="navbar-item">
          <a href="/" class="button is-primary" onClick={() => { localStorage.clear()}}>
            <span class="icon">
              <i class="fas fa-power-off"></i>
            </span>
            <span>Log Out</span>
          </a>
          {
            user && user.userType == "individualUser" ?
              <button className="button" onClick={() => { history.push('/subscription')}}>
              <span>Subscription</span>
            </button>
            : null
          }
           <button className="button ml-2" onClick={generateReport}>
              <span>Generate Report</span>
            </button>
         
          </div>
        </div>
      </div>
      </div>
    </nav>
    </>
    
  )

}

export default DashboardHeader;