
import React from 'react';

const Loader = ({size, isLarger }) => {
  return (
    <div className={`fallsWeb-loader hero  ${isLarger ?  'is-large-loader' : ''}  is-transparent is-${size}`} >
      <div className="hero-body ">
        &nbsp;
      </div>
    </div>
  )
}

export default Loader