/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import { useHistory, Redirect } from 'react-router-dom';
import { useForm } from "react-hook-form";
import config from '../../utils/config';
import DashboardFooter from '../helpers/DashboardFooter';
import DashboardHeader from '../helpers/DashboardHeader';
import moment from 'moment';
import { useState, useEffect } from 'react';
import Modal from '../helpers/Modal';

var cancelUrl = config.individual_cancel_subscription;
var updateUrl = config.individual_update_subscription;
const CalculatePriceWithDiscount = config.calculate_price_with_discount;
const IndividualSubscriptionPrice = config.indivudal_Subscription_price;
const individualSubscriptionDetails = config.getSubscrptionDetails
const DashBoardWrapper = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false)

  const OnModalToggle = () => {
    setModalOpen(!isModalOpen);
  }
  //resume
  const history = useHistory();
  let user = JSON.parse(localStorage.getItem('user'));
  const { register, handleSubmit, watch, errors } = useForm({ mode: 'onChange' });
  const token = localStorage.getItem('token');
  const [subscriptionMesssage, setSubscriptiponMessage] = useState(null);
  const [subscriptionDetails, setSubscriptionDetails] = useState(false);
  const [discountCode, setDiscountCode] = useState();
  const [discountData, setDiscountData] = useState();
  const [isDisccountError, setDiscountError] = useState();
  const [discountLoading, setDiscountLoading] = useState(false);
  const [hideCardForm, setHideCardForm] = useState(false);
  const [price, setPrice] = useState(null);
  let url;
  let payload = {};
  useEffect(() => {
    fetchPrice();
    getsubscriptionDetails()
  }, [])

  useEffect(() => {
    const timeOutId = setTimeout(() => fetchDiscountCalculation(discountCode), 1000);
    return () => clearTimeout(timeOutId);
  }, [discountCode]);

  const fetchDiscountCalculation = (discountCode) => {
    setDiscountLoading(true)
    const config = {
      method: 'post',
      url: CalculatePriceWithDiscount,
      headers: {},
      data: {
        discountCode
      }
    }
    axios(config)
      .then(function (response) {
        setDiscountError()
        setDiscountData(response.data)
        setDiscountLoading(false)
        if (response.data.discountPercentage === 100) {
          setHideCardForm(true)
        }
      })
      .catch(function (error) {
        var err = error.response.data.message
        setDiscountError(err)
        setDiscountData()
        setDiscountLoading(false)
      });
  }

  const fetchPrice = () => {
    const config = {
      method: 'get',
      url: IndividualSubscriptionPrice,
      headers: {},
    }
    axios(config)
      .then(function (response) {
        setPrice(response.data.price);
      })
      .catch(function (error) {
        var err = error.response.data.message
      });
  }

  let cancelSubscription = () => {
    if (window.confirm('Are you sure you want to cancel your subscription?')) {
      var config = {
        method: 'patch',
        url: cancelUrl,
        headers: { 'Authorization': 'Bearer ' + token },
        data : {}
      };
      axios(config)
      .then(function (response) {
        alert(response.data.message)
        localStorage.clear();
        history.push('/');
      })
      .catch(function (error) {
        console.log(error)
        alert(error.response.data.message)
      });
    }
  }

  let getsubscriptionDetails = () => {
      var config = {
        method: 'get',
        url: individualSubscriptionDetails,
        headers: { 'Authorization': 'Bearer ' + token },
        data : {}
      };
      axios(config)
      .then(function (response) {
        setSubscriptionDetails(response.data)
      })
      .catch(function (error) {
        console.log(error)
        alert(error.response.data.message)
      });
  }

  const onSubmit = (data) => {
    setLoading(true)
    if (hideCardForm) {
      payload.number = "4242424242424242";
      payload.expMonth = 12;
      payload.expYear = 2022;
      payload.cvc = "123";
    }
    else {
      payload.number = data.number;
      payload.expMonth = data.expMonth;
      payload.expYear = data.expYear;
      payload.cvc = data.cvc;
    }
    if (data.discountCode) {
      payload.discountCode = data.discountCode;
    }

    const config = {
      method: 'patch',
      url: updateUrl,
      headers: { 'Authorization': 'Bearer ' + token },
      data: payload
    }
    axios(config)
      .then(function (response) {
        setLoading(false)
        localStorage.setItem('user', JSON.stringify(response.data.user ? response.data.user : {}));
        setSubscriptiponMessage(response.data)
        setModalOpen(true)
        
      })
      .catch(function (error) {
        alert(error.response.data.message)
        setLoading(false)
      });
  }

  const paymentForm = () => {
    return (
      <>
        <br></br>
        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field">
              <p className={`control has-icons-left  ${discountLoading ? 'is-loading' : ''} `}>
                <input
                  className="input "
                  type="text"
                  placeholder="Discount Code"
                  onChange={e => setDiscountCode(e.target.value)}
                  name="discountCode"
                  ref={register}
                  disabled={discountLoading ? true : false}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-percentage"></i>
                </span>
                {isDisccountError && <p className="help is-danger">{isDisccountError}</p>}
              </p>
            </div>
            <div className="field">
              <div className="control">
                <input className="input" type="text" value={`Total Amount : $ ${discountData ? discountData.totalPrice : price + '.00'} `} readonly disabled />
                {discountData && <p className="help is-success has-text-weight-bold">You got {discountData.discountPercentage}% discount</p>}
              </div>
            </div>
          </div>
        </div>
        {
          hideCardForm ?
            null :
            <div>
              <div className="field">
                <p className="control has-icons-left">
                  <input className={`input ${errors.number && 'is-danger'}`} type="number" pattern="\d*" placeholder="Card Number" name="number" ref={register({ required: "Credit Card number required", minLength: { value: 16, message: 'Card number minimum 16 digit' }, maxLength: { value: 16, message: 'Card number maximum 16 digit' } })} />
                  <span className="icon is-small is-left">
                    <i className="far fa-credit-card"></i>
                  </span>
                  {errors.number && <p className="help is-danger">{errors.number.message}</p>}
                </p>
              </div>
              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <p className="control is-expanded has-icons-left has-icons-right">
                      <input className={`input ${errors.expMonth && 'is-danger'}`} min='1' max="12" type="number" placeholder="Exp. Month" name="expMonth" ref={register({
                        required: 'Exp. Month is Required.',
                        validate: {
                          positive: value => parseInt(value, 10) > 0 || 'should be greater than 0',
                          lessThanTen: value => parseInt(value, 10) < 13 || 'should be lower than 13',
                        }
                      })} />
                      <span className="icon is-small is-left">
                        <i className="fas fa-calendar-week"></i>
                      </span>
                      <span className={`icon is-small is-right ${!errors.expMonth ? 'has-text-success' : 'has-text-danger'}`}>
                        <i className="fas fa-check"></i>
                      </span>
                      {errors.expMonth && <p className="help is-danger">{errors.expMonth.message}</p>}
                    </p>
                  </div>
                  <div className="field">
                    <p className="control is-expanded has-icons-left has-icons-right">
                      <input className={`input ${errors.expYear && 'is-danger'}`} type="number" placeholder="Exp. Year" name="expYear" ref={register({
                        required: 'Exp. year is required.',
                        validate: {
                          positive: value => parseInt(value, 10) >= 2021 || 'should be greater than 2021',
                          lessThanTen: value => parseInt(value, 10) < 2041 || 'should be lower than 2040',
                        }
                      })}
                        max="2040"
                        min="2021"
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-calendar-week"></i>
                      </span>
                      <span className={`icon is-small is-right ${!errors.expYear ? 'has-text-success' : 'has-text-danger'}`}>
                        <i className="fas fa-check"></i>
                      </span>
                      {errors.expYear && <p className="help is-danger">{errors.expYear.message}</p>}
                    </p>
                  </div>
                  <div className="field">
                    <p className="control is-expanded has-icons-left has-icons-right">
                      <input className={`input ${errors.cvc && 'is-danger'}`} type="number" placeholder="CVC" name="cvc" ref={register({ required: 'CVC is required', minLength: 3, maxLength: 3 })} />
                      <span className="icon is-small is-left">
                        <i className="fas fa-credit-card"></i>
                      </span>
                      <span className={`icon is-small is-right ${!errors.cvc ? 'has-text-success' : 'has-text-danger'}`}>
                        <i className="fas fa-check"></i>
                      </span>
                      {errors.cvc && <p className="help is-danger">{errors.cvc.message}</p>}
                    </p>
                  </div>
                </div>
              </div>
            </div>


        }
      </>
    )
  }

  return (
    !user ? <Redirect to="/" /> : 
    user && user.userType != "individualUser" ? <Redirect to="/dashboard" /> :
    <div className="main" style={{ overflowY: 'hidden', overflowX: 'hidden', position: 'static', height: 'auto' }}>
      <DashboardHeader />
      <main className="main-content-dashboard px-2">
        <div className="columns has-padding-mobile">
          <div className="column is-three-fifths is-offset-one-fifth mt-2">
            <div className="container">
              {
                !user.isSubscribed ? 
                <div className="card">
                <header className="card-header">
                  <p className="card-header-title">Subscribe Again</p>
                </header>
                <div className="card-content">
                {paymentForm()}
                </div>
                  <footer className="card-footer p-4">
                    <button
                      type="submit"
                      className={`card-footer-item is-primary button is-ghost ${isLoading ? 'is-loading' : ''}`}
                      onClick={handleSubmit(onSubmit)}
                    >Subscribe
                    </button>
                  </footer>
                </div>
                :
              
              <div className="card">
                <header className="card-header">
                    <p className="card-header-title">
                      Subscription Details
                    </p>
                </header>
                <div className="card-content">
                {isModalOpen &&
                  <Modal
                    isActive={isModalOpen}
                    title={'Payment Info'}
                    OnModalToggle={OnModalToggle}
                    data={subscriptionMesssage}
                  />
                }
                {
                  subscriptionDetails ? 
                  <div className="content">
                    <p className="subtitle is-5">Subscription Price: ${subscriptionDetails.subscriptionPrice.toFixed(2)}<span className='has-text-success is-size-7'> (Discount will be applied if applicable.)</span></p>
                     { subscriptionDetails.subscriptionPrice ?
                     <p className="subtitle is-5">Amount Charge for this month: ${subscriptionDetails.lastCharge}</p>
                     : null }
                    <p className="subtitle is-5">Plan: Monthly</p>
                    <p className="subtitle is-5">Next Billing Date: { moment(subscriptionDetails.subscriptionEndDate).format('dddd, MMMM Do YYYY')}</p>
                    <button class="button is-danger" onClick={cancelSubscription}>Cancel Subscription</button>
                </div>
                  : null
                }
                 
                </div>
              </div>}
            </div>
          </div>
        </div>
      </main>
      <DashboardFooter />
    </div>
  )
}
export default DashBoardWrapper