
import Confetti from 'react-confetti';

const PopUpTheEnd = ({isActive, showResults}) => {
  var height = window.innerHeight
  var width = window.innerWidth

  return (
    <>
      <div  class={`modal modal-fx-fadeInScale  ${isActive ? 'is-active' : ''}`}>
      <Confetti
      width={width}
      height={height}
      />
        <div class="modal-background"></div>
        <div class="modal-content ">
        <div className="card">
      <div className="card-content has-text-centered">
      <span class="icon-text">
        <span class="icon">
        <i class="fas fa-award  has-text-success"></i>
        </span>
        <span>Congrats!!! You have Finished The Game.</span>
      </span>
        <span>
        <p></p>
        </span>
      </div>
    <footer class="card-footer">
      <a  class="card-footer-item has-background-success has-text-dark" onClick={showResults}>Show Results</a>
      {/* <a  class="card-footer-item has-background-warning has-text-dark" onClick={handlePause}>Not Now</a> */}
    </footer>
    </div>
      </div>
    </div>
    </>
  )
}

export default PopUpTheEnd;