import DashBoardWrapper from './DashBoardWrapper';
import Footer from '../helpers/Footer';

const Dashboard = (props) => {
  
  return(
    <div>
      <DashBoardWrapper {...props} />
    </div>
  )
}
export default Dashboard;