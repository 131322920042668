import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";

const ProtectedRoute = ({component: Component, ...rest}) => {

  const token = localStorage.getItem('token');
   return  (
    <Route 
      {...rest}
      render={props => (
        token ? <Component {...props} /> : <Redirect to="/"  />
      )}
    />
  ) 
}

export default  ProtectedRoute